import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog1 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #1</h1>
                <p>By: David Castaneda</p>
                <p>Date: 9/8/202</p>


                <h2>Introduction - Assignments Worked On</h2>

                <p>Welcome back to my blog site! For Blog 1, I will be discussing various assignments I worked on this
                    week, things I learned and even things I struggled with. Once again, I will be discussing the stuff
                    I worked on in my COMP 484 and CIT 480 classes. This week in COMP 484 I was given three homework
                    assignments all relating to HTML. In addition to those assignments, I was also given a lab to work
                    on in my CIT 480 class. This lab consisted of using a program known as Docker to build a lamp stack.
                    In the sections below, I’ll discuss each assignment, covering the steps I took to complete them. At
                    the end, you can find a reflection where I will cover what I learned.</p>

                <h2>CIT 480 - Lab 1 - Lamp Stack</h2>

                <p>This assignment consisted of installing certain and using it to install packages via the command line
                    and using web server software to display the webpage. The first step I had to do was install Docker.
                </p>

                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/docker.png" alt="Your Name" />
                </Row>

                <dl>
                    <dt><b>Docker</b></dt>
                    <dd>Docker is a set of platform as a service products that use OS-level virtualization to deliver
                        software in packages called containers. Containers are isolated from one another and bundle
                        their own software, libraries and configuration files; they can communicate with each other
                        through well-defined channels.</dd>
                </dl>
                <p>To install docker, visit <a href="https://www.docker.com/">docker.com</a> and click on get started.
                </p>
                <p> Then click on the download tab and select your corresponding operating system.</p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/docker2.png" alt="Your Name" />
                    <img className="img-fluid" src="blogimages/misc/docker3.png" alt="Your Name" />
                </Row>

                <p>After I got familiar with a few of the Docker commands, the next step was to install Apache.</p>

                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/apache.png" alt="Your Name" />
                </Row>

                <dl>
                    <dt><b>Apache</b></dt>
                    <dd>The Apache HTTP Server, colloquially called Apache, is a free and open-source cross-platform web
                        server software, released under the terms of Apache License 2.0. Apache is developed and
                        maintained by an open community of developers under the auspices of the Apache Software
                        Foundation.</dd>
                </dl>

                <p> In order to install Apache, and to check the version, type these commands into the command line on
                    your terminal: </p>

                <pre> $ apt-get install<br></br> apache2 $ apache2 -v</pre>

                <p>Then to start it, chack the status and test it, input the following commands:</p>
                <pre>
service apache2 start<br></br>

service apache2 statuslocalhost:8080
    
    </pre>
                <p>In the lab, I also used a database management system known as MySQL</p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/sql.png" alt="Your Name" />
                </Row>


                <dl>
                    <dt><b>MySQL</b></dt>
                    <dd>MySQL is an open-source relational database management system. Its name is a combination of
                        "My", the name of co-founder Michael Widenius's daughter, and "SQL", the abbreviation for
                        Structured Query Language.</dd>
                </dl>
                <p> To install MySQL and to verify the installation, type the following commands:</p>
                <pre>
$ apt-get install -y mysql-client<br></br>
$ dpkg --get-selections | grep mysql
</pre>
                <p>I then went on to install PHP and all of it's required packages
                </p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/php.svg" alt="Your Name" />
                </Row>
                <dl>
                    <dt><b>PHP</b></dt>
                    <dd>PHP is a general-purpose scripting language that is especially suited to web development. It was
                        originally created by Danish-Canadian programmer Rasmus Lerdorf in 1994; the PHP reference
                        implementation is now produced by The PHP Group.</dd>
                </dl>
                <p> In order to install PHP and all of the packages, and to check the version, type these commands into
                    the command line on your terminal: </p>
                <pre>
    $ apt-get install -y php libapache2-mod-php php-mysql php7.2-cli php7.2-curl php7.2-gd php7.2-mbstring php7.2-mysql php7.2-xmlphp-xml<br></br>
    php -v
    </pre>
                <p>The final thing I needed to install was Composer

                </p>

                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/composer.png" alt="Your Name" />
                </Row>

                <dl>
                    <dt><b>Composer</b></dt>
                    <dd>Composer is an application-level package manager for the PHP programming language that provides
                        a standard format for managing dependencies of PHP software and required libraries. It was
                        developed by Nils Adermann and Jordi Boggiano, who continue to manage the project.</dd>
                </dl>
                <p>In order to install composer type this command into the command line on your terminal:</p>
                <pre>$ curl -sS https://getcomposer.org/installer | php ----install-dir=/usr/local/bin --filename=composer

</pre>
                <p>After installing all of these packages, I was able to successfully set up a LAMP stack and display it
                    on a a web page. Here was my output:</p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/l1output.png" alt="Your Name" />
                    <img className="img-fluid" src="blogimages/misc/l1output2.png" alt="Your Name" />
                </Row>

        </div>
    </Container>
</div>
);
}

export default Blog1;