import './Personal.css';
import '../Main.css';



import LazyHero from 'react-lazy-hero';
import { Row, Col, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";
import Timeline from '../Timeline/Timeline';



function Personal() {
return (
<div>
  <LazyHero parallaxOffset="20" // isFixed="false" // isCentered="false" transitionTimingFunctio="ease-in-out"
    minHeight="70vh" color="black" opacity="0.6" imageSrc="/meSpringfield.jpg">
    <h3 className="whiteh3 heroFont">Personal Life</h3>
    <p className="power">Get to know me on a personal level</p>
  </LazyHero>



  <Container fluid className="greyBox wrapper spacer">
  <h1 className='myH1'>Timeline</h1>
      <p>
        Join me on a journey
      </p>

      <Row className="spacer justify-content-center">
      <h3 className='bigIcon'><i class="fa-solid fa-person-hiking"></i></h3>
      
</Row>
</Container> 
  
<Timeline></Timeline>
  
  <Container fluid className="greyBox wrapper">
    <Row className="spacer">
      <Col lg={12}>
      <h1 className='myH1'>Hobbies and Interests</h1>
      <p>
        The things that bring joy to my life
      </p>
      </Col>
    </Row>


    <div fluid className="redFlame">
      <h3 className="center whiteh3 hobbiesTitle">Sports</h3>
    </div>

    <Row className="spacer">
      <Col lg={3}>
      <h4 className='quoteIcon center spacer'> <i class="fa-solid fa-baseball-bat-ball"></i> <i
          class="fa-solid fa-football"></i> <i class="fa-solid fa-basketball"></i> <i
          class="fa-solid fa-hockey-puck"></i>
      </h4>
      </Col>
      <Col lg={9}>
      <h1>Why Sports?</h1>
      <p>
        I like sports for a number of reasons. Some of my fondest memories come from playing pick up baseball games with
        my after school care friends. As I grew up, I began to appreciate the importance and impact of sports on a
        larger level. In addition to being fun, it promotes a healthy and active lifestyle. It also serves as a great
        way to encourage kids, and even adults, to be the best they can possibly be.
      </p>
      </Col>
    </Row>

    <Row className="spacer">
      <Col lg={3}>

      <div className="">
        <img className="img-thumbnail bioPic" src="/myTEAMS.png" alt="A compilation of David Castaneda's favorite teams: Angels, Rams, Lakers, Ducks" />
      </div>

      </Col>
      <Col lg={9}>
      <h1>My Fandom</h1>
      <p>
        MLB is definitely my favorite, but I am also a huge fan of the NBA and NFL. While they constantly bring my pain,
        the Los Angeles Angels are my baseball team. As for basketball, Kobe Bryant made me fall in love with the game
        of basketball growing up, so the Los Angeles Lakers are my go-to. Growing up in Los Angeles, when I was younger
        LA did not have an NFL team. It wasn't until the Rams came here in 2017 that I started investing time into
        watching football. While never really into hockey, I root for the Ducks by proxy.
      </p>
      </Col>
    </Row>

    <Row className=" spacer justify-content-center">
      <Col lg={4}>

      <Row>
        <div className="">
          <img className="img-thumbnail" src="/meSTAPLES.jpg" alt="David Castaneda posing at the Staples Center 20th anniversary mural" />
        </div>
      </Row>

      <Row>
        <div className="">
          <img className="img-thumbnail" src="/meBIGA.jpg" alt="David Castaneda posing at the main entrance of Angel Stadium in Anaheim, California" />
        </div>
      </Row>
      </Col>
      <Col lg={4}>
      <div className="">
        <img className="img-thumbnail" src="/meBIGA2.jpg" alt="David Castaneda sitting on the first base stands at Angel Stadium in Anaheim, California" />
      </div>
      </Col>
      <Col lg={4}>
      <div className="">
        <img className="img-thumbnail" src="/meSTAPLES2.jpg" alt="David Castaneda posing in front of the Oscar De La Hoya statue at Staples Center" />
      </div>
      </Col>
    </Row>


    <div fluid className="spacer greenFlame">
      <h3 className="center whiteh3 hobbiesTitle">Music</h3>
    </div>

    <Row className="spacer">
      <Col lg={3}>

      <h4 className='quoteIcon center spacer'> <i class="fa-solid fa-music"></i> <i
          class="fa-solid fa-headphones-simple"></i>
      </h4>

      </Col>
      <Col lg={9}>
      <h1>My Music</h1>
      <p>
        As a kid, I never had a strong interest in music. It wasn't until I got older and started really listening to songs and albums thoroughly that I started to appreciate music and musicians a hell of a lot more. It amazes me what some musicians are able to do with their art. I personally enjoy bits of all music, but am a huge fan of Hip Hop and Rap. Albums such as <i>4 your eyez only</i> by J cole, <i>To Pimp a Butterfly</i> by Kendrick Lamar and <i>The Eminem Show</i> by Eminem amaze me in the ways they combine catchy songs and socially impactful storytelling so seamlessly.   
      </p>
      </Col>
    </Row>

    <Row>
      <Col>
      <h4 className="myH1">Artists</h4>
      
      <Row className=" spacer justify-content-center">
      <Col lg={3}>
      <div className="">
        <img className="bioPic" src="/jcole.png" alt="J Cole" />
      </div>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="bioPic" src="/kdot.png" alt="Kendrick Lamar" />
      </div>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="bioPic" src="/eminem.png" alt="Eminem" />
      </div>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="bioPic" src="/drake.png" alt="Drake" />
      </div>
      </Col>

    </Row>
      </Col>
    </Row>
    
    <Row className="justify-content-center">
      <Col lg={3}>
      <div className="">
        <img className="bioPic" src="/joey.png" alt="Joey Badass" />
      </div>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="bioPic" src="/logic.png" alt="Logic" />
      </div>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="bioPic" src="/kanye.png" alt="Kanye West" />
      </div>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="bioPic" src="/postmalone.png" alt="Post Malone" />
      </div>
      </Col>

    </Row>


    <Row className='justify-content-center'>
      <div class="spotifyLink spacer "><a target="_blank" rel="noreferrer"
          href="https://open.spotify.com/playlist/2SMSIFNlUlAhm6KWLLYPIJ?si=2da51de2e5564e56"><i class="fab fa-spotify"></i> Check Out
          my Spotify profile here!</a></div>

    </Row>



    <div fluid className="spacer blueFlame">
      <h3 className="center whiteh3 hobbiesTitle">Travel</h3>
    </div>

   

    <Row className="spacer">
      <Col lg={3}>

      <h4 className='quoteIcon center spacer'> 
      <i class="fa-solid fa-plane-departure"></i> <i class="fa-solid fa-suitcase-rolling"></i>
      </h4> 

      </Col>
      <Col lg={9}>
      <h1>Oahu, Hawaii</h1>
      <small>March 2024</small>
      <p>
          My first experience traveling was my trip to the Island of Oahu in Hawaii. From the beaches of Waikiki, to the city of Honolulu, all the way to the North Shore of the island my trip to Hawaii was unforgettable. Highlights of the trip include Ziplining in Kapolei, visiting the Pearl Harbor Memorial and ATV riding in Wailalua. While all those activities were fun, the pinnacle of the trip was Sky Diving in the North Shore of Oahu. It was a once in a lifetime experience that I will never forget.    
      </p>
      </Col>
    </Row>

    <Row className=" spacer justify-content-center">
      <Col lg={3}>

     
      <div className="">
        <img className="img-thumbnail" src="/hawaii/planeShot.jpg" alt="" />
      </div>
      <small>Arriving at HNL in Honolulu</small>

      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/outriggerWaikiki.jpg" alt="" />
      </div>
      <small>The luxurious Outrigger Resort and Hotel</small>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/dukes.jpg" alt="" />
      </div>
      <small>Breakfast at Duke's</small>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/honoluluSkyline.jpg" alt="Honolulu Skyline" />
      </div>
      <small>Skyline of Honolulu</small>
      </Col>
    </Row>

    <Row className=" spacer justify-content-center">
      <Col lg={3}>

     
      <div className="">
        <img className="img-thumbnail" src="/hawaii/royalHawaiian.jpg" alt="Royal Hawaiian hotel" />
      </div>
      <small>The historic Royal Hawaiian Hotel</small>

      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/luau.jpg" alt="Luau performers" />
      </div>
      <small>Traditional Polynesian luau at The Royal Hawaiian</small>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/meBeach.jpg" alt="david at the beach" />
      </div>
      <small>Enjoying the beautiful beaches of Waikiki</small>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/waterfall.jpg" alt="Waterfall" />
      </div>
      <small>Small waterfall on the sidewalk of Waikiki Beach </small>
      </Col>
    </Row>

    <Row className=" spacer justify-content-center">
      <Col lg={3}>

     
      <div className="">
        <img className="img-thumbnail" src="/hawaii/ziplining.jpg" alt="zipline venue" />
      </div>
      <small>Ziplining in Kapolei </small>

      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/pearlHarborEntrance.jpg" alt="Entrance or Pearl Harbor Memorial" />
      </div>
      <small>Pearl Harbor Museum and Memorial</small>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/pearlHarborFlag.jpg" alt="American Flag at Pearl Harbor" />
      </div>
      <small>American Flag at Pearl Harbor</small>
      </Col>
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/pearlHarbor.jpg" alt="the water at Pearl Harbor" />
      </div>
      <small>The melancholic waters of Pearl Harbor</small>
      </Col>
    </Row>
<hr></hr>
<Row className='spacer justify-content-center'>
     

      <div className="">
        <img className="img-thumbnail" src="/hawaii/skydiveHawaii.png" alt="" />
      </div>
     </Row>

    <Row className="spacer">
      <Col lg={3}>

      <div className="">
        <img className="img-thumbnail" src="/hawaii/meParachute.jpg" alt="david with a parachute" />
      </div>

      </Col>
      <Col lg={9}>
      <h2>Skydiving: The Experience of a Lifetime</h2>
      <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, numquam iusto. Doloribus minus pariatur aperiam atque enim, error facere quod adipisci officiis quaerat vitae nam laudantium illo. Quas, harum doloremque.Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, numquam iusto. Doloribus minus pariatur aperiam atque enim, error facere quod adipisci officiis quaerat vitae nam laudantium illo. Quas, harum doloremque.Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, numquam iusto. Doloribus minus pariatur aperiam atque enim, error facere quod adipisci officiis quaerat vitae nam laudantium illo. Quas, harum doloremque.
      </p>
      </Col>
    </Row>


    <Row className=" spacer justify-content-center">
      <Col lg={4}>

     
      <div className="">
        <img className="img-thumbnail" src="/hawaii/airField.jpg" alt="david walking to the plane" />
      </div>
      <small>Walking to the Plane</small>

      </Col>
      {/* <Col lg={4}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/skydivePlane.jpg" alt="Skydive Hawaii plane" />
      </div>
      <small>The plane that took me up to the sky</small>
      </Col> */}
      <Col lg={4}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/meOnPlane.jpg" alt="David Boarding Plane" />
      </div>
      <small>Boarding the plane</small>
      </Col>
      <Col lg={4}>
      <div className="">
        <img className="img-thumbnail" src="/hawaii/meOnPlane2.jpg" alt="David On the Plane" />
      </div>
      <small>Taking it all in, no going back now...</small>
      </Col>
    </Row>


    <h2>3...2...1...JUMP!</h2>
    <Row className='spacer justify-content-center'>
     
        <img className="img-thumbnail" src="/hawaii/skydiveVid.gif" alt="" />
     </Row>

    <Row className=" spacer justify-content-center">
    <h1>More destinations coming soon! 🤞</h1>
      </Row>

<Row className='spacer justify-content-center'>
<i>Want to know more about me? <Link to="/contact">Contact Me!</Link></i>
</Row>

  </Container>
</div>
);
}

export default Personal;