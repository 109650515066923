import Blog3 from '../../Components/Blogs/Blog3';


export default function Blog3Page(){
    return(
        <div>
            <Blog3/>
        </div>
    )

}