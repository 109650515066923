import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog0 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #2</h1>
                <p>By: David Castaneda</p>
                <p>Date: Sep 18, 2020</p>


                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/css.png" alt="Your Name" />
                </Row>
                <Row className="spacer justify-content-start">
                    <h2>Introduction - Assignments Worked On</h2>

                    <p>For Blog #2, I will be writing about the work I did in my COMP 484 class, similar to the last 2
                        blogs. Last week we talked about certain HTML elements that are useful in regards to structuring
                        your webpage. This pretty much concluded basic HTML. The next thing we were introduced to was
                        CSS. I will go into detail on what this means, but in a nutshell: it is what makes your website
                        look good. Going into this semester I was looking forward to learning this. I have a little bit
                        of an understanding of CSS, but I am eager to learn methods, tips, strategies and practices that
                        can elevate my web development to the next level. For those unaware of what CSS is, a breif
                        synopsis follows.</p>
                </Row>
                <h2>COMP 484 - Introduction to CSS</h2>
                <p>A website needs a number of thing to be successful, and one of them is style. This is where CSS comes
                    into play.</p>
                <dl>
                    <dt><b>CSS</b></dt>
                    <dd>Cascading Style Sheets is a style sheet language used for describing the presentation of a
                        document written in a markup language such as HTML. CSS is a cornerstone technology of the World
                        Wide Web, alongside HTML and JavaScript.
                    </dd>
                </dl>
                <p>Down below, you will see some examples regardingf the presence and abscence of CSS, which will show
                    how important implementing it into your website is.</p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/comp484-2.png" alt="Your Name" />
                    <img className="img-fluid" src="blogimages/misc/comp484-2.png" alt="Your Name" />
                </Row>
                <p>In the two pictures above you can see two images of the same website. The first screenshot consists
                    of pure HTML. This means that the website has all the necessary source code to display a basic
                    webpage. Despite being able to display a page, this page is not so appealing to the eye. There is no
                    color, organization or any sort of style.
                    In the second screenshot, you are able to see the same webpage, with the same source HTML code, but
                    with one major difference, an external stylesheet. Stylesheets are seperate .css files that contain
                    all the properties that enable a website to look pretty. You are able to see right away, that in
                    comparison to the first imaage, this one is much more organized, appealing and overall more styled.
                </p>
                <dl>
                    <dt><b>CSS Elements</b></dt>
                    <dd>Here are a list of some elements that are used in CSS:
                        <ul>
                            <li>margin</li>
                            <li>padding</li>
                            <li>float</li>
                            <li>display</li>
                            <li>color</li>
                            <li>font</li>
                            <li>text</li>
                        </ul>
                    </dd>
                </dl>

                <p> Below is an example of some basic CSS syntax: </p>
                <pre>
h1	&#123;<br></br>
color: pink;<br></br>
font-family: Courier;<br></br>
background-color: black;<br></br>
&#125;
</pre>
                <p>You start off a basic CSS block of code by determining which element you want to style, in this case
                    it is h1. Next, inside two curly brackets, you add in the styling elements that you want to apply.
                    Once all the style elements are marked down, every h1 will be styled according to the elements in
                    the CSS file. Here is an example of what it looks like before and after.</p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/comp484-3.png" alt="Your Name" />
                </Row>



        </div>
    </Container>
</div>
);
}

export default Blog0;