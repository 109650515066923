import './Blogs.css';
import '../Main.css';
import { Row, Col, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";



function BlogsHomepage () {
return (


<div>


    <Container fluid className="greyBox">
        <Row className="spacer justify-content-center">
            <h1>Blogs / Home</h1>
        </Row>


        <Row className="justify-content-center">


            
            <Col lg={3} className="bgBlogs">
            <Link to="/blog22">
            <Row className="justify-content-center">
                <h1>Blog #22</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Route 53</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>May 7th, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/blogimages/route53.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>

            <Col lg={3} className="bgBlogs">
            <Link to="/blog21">
            <Row className="justify-content-center">
                <h1>Blog #21</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Elastic Beanstalk</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Apr 30th, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/blogimages/beanstalk.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>

            
           
        </Row>

        <Row className="justify-content-center">


            
            <Col lg={3} className="bgBlogs">
            <Link to="/blog20">
            <Row className="justify-content-center">
                <h1>Blog #20</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>AWS Amplify</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Apr 16th, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/blogimages/amplify.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>

            
            <Col lg={3} className="bgBlogs">
            <Link to="/blog19">

            <Row className="justify-content-center">
                <h1>Blog #19</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>EmailJS</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Apr 9, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/blogimages/emailjs.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Link to="/blog18">
            <Row className="justify-content-center">
                <h1>Blog 18</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>React Routes</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Apr 2, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/blogimages/react-router.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
        </Row>
     
       <Row className="justify-content-center">
            <Col lg={3} className="bgBlogs">
            <Link to="/blog17">
            <Row className="justify-content-center">
                <h1>Blog #17</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>React Components</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Mar 26, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/components.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Link to="/blog16">
            <Row className="justify-content-center">
                <h1>Blog #16</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>React</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Mar 12, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/logo512.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Link to="/blog15">
            <Row className="justify-content-center">
                <h1>Blog #15</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>HTTP Methods</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Mar 5, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/postman.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>

        </Row>

        <Row className="justify-content-center">
            <Col lg={3} className="bgBlogs">
            <Link to="/blog14">
            <Row className="justify-content-center">
                <h1>Blog #14</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>AWS EC2</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Feb 26, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/ec2.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Link to="/blog13">

            <Row className="justify-content-center">
                <h1>Blog #13</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Git Actions</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Jan 20th, 2021</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/git.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>

            <Col lg={3} className="bgBlogs">
            <Link to="/blog12">
            <Row className="justify-content-center">
                <h1>Blog #12</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Google Maps API</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Dec 4th, 2020</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/maps.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
        </Row>

        <Row className="justify-content-center">
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #11</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Slick Carousel</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Nov 20th, 2020</h3>
                <small class="soon">Coming soon!</small>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/slick.png" alt="Your Name" />
            </Row>
            </Col>

            <Col lg={3} className="bgBlogs">
            <Link to="/blog10">
            <Row className="justify-content-center">
                <h1>Blog #10</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>CSS Animations</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Nov 13th, 2020</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/animation.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #9</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Alertify.js</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Nov 6th, 2020</h3>
                <small class="soon">Coming soon!</small>

            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/alertify.png" alt="Your Name" />
            </Row>
            </Col>
        </Row>

        <Row className="justify-content-center">
            <Col lg={3} className="bgBlogs">
            <Link to="/blog8">
            <Row className="justify-content-center">
                <h1>Blog #8</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Node Package Manager</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Oct 30th, 2020</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/npm.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>

            <Col lg={3} className="bgBlogs">
            <Link to="/blog7">
            <Row className="justify-content-center">
                <h1>Blog #7</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Node.js</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Oct 23rd, 2020</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/node.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Link to="/blog6">
            <Row className="justify-content-center">
                <h1>Blog #6</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Bootstrap</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Oct 16th, 2020</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/bootstrap.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
        </Row>

        <Row className="justify-content-center">
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #5</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Resume Site</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Oct 9th, 2020</h3>
                <small class="soon">Coming soon!</small>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/resume.png" alt="Your Name" />
            </Row>
            </Col>

            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #4</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Header Style</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Oct 2nd, 2020</h3>
                <small class="soon">Coming soon!</small>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/html.png" alt="Your Name" />
            </Row>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #3</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Page Structure</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Sep 25th, 2020</h3>
                <small class="soon">Coming soon!</small>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/structure.png" alt="Your Name" />
            </Row>
            </Col>
        </Row>

        <Row className="justify-content-center">

            <Col lg={3} className="bgBlogs">
            <Link to="/blog2">
            <Row className="justify-content-center">
                <h1>Blog #2</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Intro to CSS</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Sep 18th, 2020</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/css.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>





            <Col lg={3} className="bgBlogs">
            <Link to="/blog1">
            <Row className="justify-content-center">
                <h1>Blog #1</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>LAMP Stack</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Sep 8th, 2020</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="blogimages/lamp.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Link to="/blog0">
            <Row className="justify-content-center">
                <h1>Blog #0</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>My First Blog</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Aug 27th, 2020</h3>
            </Row>

            <Row className="justify-content-center">

                <img className="blogPic" src="blogimages/glitch.png" alt="Your Name" />
            </Row>
            </Link>
            </Col>


        </Row>






        {/* <Row className="justify-content-center">
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Title</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Date</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/logo512.png" alt="Your Name" />
            </Row>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Title</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Date</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/logo512.png" alt="Your Name" />
            </Row>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Title</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Date</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/logo512.png" alt="Your Name" />
            </Row>
            </Col>
        </Row> */}

        {/* <Row className="justify-content-center">
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Title</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Date</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/logo512.png" alt="Your Name" />
            </Row>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Title</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Date</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/logo512.png" alt="Your Name" />
            </Row>
            </Col>
            <Col lg={3} className="bgBlogs">
            <Row className="justify-content-center">
                <h1>Blog #</h1>
            </Row>
            <Row className="justify-content-center">
                <h2>Title</h2>
            </Row>
            <Row className="justify-content-center">
                <h3>Date</h3>
            </Row>
            <Row className="justify-content-center">
                <img className="blogPic" src="/logo512.png" alt="Your Name" />
            </Row>
            </Col>
        </Row> */}


    </Container>



</div>
);
}

export default BlogsHomepage;