import './Socials.css';
import '../Main.css';
import { Row, Col, Container } from 'react-bootstrap';

function Socials() {
return (
<div>
    <Container fluid className="otherSocials">
        <Row className="spacer justify-content-center">
            <h1>Follow Me Everywhere!</h1>
        </Row>
        <div className="socials-wrap">
            <Row className="justify-content-center">
                <Col lg={2} className="instagram">
                <a id="instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/teggydc/">
                    <i className="grow fab fa-instagram"></i>
                </a>
                </Col>
                <Col lg={2} className="twitter">
                <a id="twitter" target="_blank" rel="noreferrer" href="https://www.twitter.com/RefillDranks/">
                    <i className="grow fab fa-twitter"></i>
                </a>
                </Col>
                <Col lg={2} className="github">
                <a id="github" target="_blank" rel="noreferrer" href="https://github.com/dec98524">
                    <i className="grow fab fa-github-square"></i>
                </a>
                </Col>
                <Col lg={2} className="linkedin">
                <a id="linkedin" target="_blank" rel="noreferrer"
                    href="https://www.linkedin.com">
                    <i className="grow fab fa-linkedin"></i>
                </a>
                </Col>
            </Row>

            <Row className="justify-content-center">
                <Col lg={2} className="spotify">
                <a id="spotify" target="_blank" rel="noreferrer"
                    href="https://open.spotify.com/user/dec115?si=E8UUnwS_SPeJtP_HwxiKTg">
                    <i className="grow fab fa-spotify"></i>
                </a>
                </Col>
                <Col lg={2} className="tiktok">
                <a id="tiktok" target="_blank" rel="noreferrer" href="https://www.tiktok.com/@trikldetail">
                    <div class="tiktoklogo">
                        <i class="grow fab fa-tiktok"></i>
                    </div>
                </a>
                </Col>
                <Col lg={2} className="facebook">
                <a id="facebook" target="_blank" rel="noreferrer"
                    href="https://www.facebook.com/david.castaneda.5205622">
                    <i className="grow fab fa-facebook-f"></i>
                </a>
                </Col>
                <Col lg={2} className="reddit">
                <a id="reddit" target="_blank" rel="noreferrer" href="https://www.reddit.com/">
                    <i className="grow fab fa-reddit"></i>
                </a>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={2} className="psn">
                <a id="psn" target="_blank" rel="noreferrer" href="https://my.playstation.com/profile/Thrust-Bot">
                    <i className="grow fab fa-playstation"></i>
                </a>
                </Col>
                <Col lg={2} className="steam">
                <a id="steam" target="_blank" rel="noreferrer"
                    href="https://steamcommunity.com/profiles/76561198356805535/">
                    <i className="grow fab fa-steam"></i>
                </a>
                </Col>
                <Col lg={2} className="nintendo">
                <a id="nintendo" href="/">
                    <i className="grow fab fa-nintendo-switch"></i>
                </a>
                </Col>
                <Col lg={2} className="cord">
                <a id="discord" target="_blank" rel="noreferrer" href="https://discord.gg/pUFykkTC">
                    {/* <i className="grow fab fa-discord"></i> */}
                    <i class="grow fa-brands fa-discord"></i>
                </a>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={2} className="yt">
                <a id="yt" target="_blank" rel="noreferrer" href="https://www.youtube.com/">
                    <i className="grow fab fa-youtube"></i>
                </a>
                </Col>
                <Col lg={2} className="twitch">
                <a id="twitch" target="_blank" rel="noreferrer" href="https://www.twitch.tv/advancedstadia">
                    <i className="grow fab fa-twitch"></i>
                </a>
                </Col>
                <Col lg={2} className="snap">
                <a id="snap" target="_blank" rel="noreferrer" href="https://www.snapchat.com/add/thrustbot">
                    <i className="grow fab fa-snapchat-ghost"></i>
                </a>
                </Col>
                <Col lg={2} className="cashapp">
                <a id="cashapp" target="_blank" rel="noreferrer" href="https://cash.app/$davidcastanedacash">
                    <i className="grow fas fa-dollar-sign"></i>
                </a>
                </Col>
            </Row>
        </div>
    </Container>

    <br></br>
</div>
);
}

export default Socials;