import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog12 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #13</h1>
                <p>By: David Castaneda</p>
                <p>Date: 2/19/2021</p>


                <h1>
                    Introduction - Git Commands and GitHub
                </h1>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/git1.jpg" alt="Your Name" />

                </Row>
                <p>
                    Welcome back to another semester. I am glad to be in my final semester of college and am very
                    motivated to finish strong! For this blog, I will be going over something that I know I will be
                    using a lot this semester for this class: Git commands. This is something that I am familiar with,
                    asa I used it a bit last semester, but am not a master in. In this blog, I will be oing over what
                    Git commands are, how to install them onto your command line and how to use them when collaborating
                    on projects with your team members in GitHub. GitHub is quite possibly the number one way developers
                    collaborate on coding projects since it is a quick and easy way to store large amounts of code and
                    update that code. Git commands allow you to do all of this from the comfort of your good ol' command
                    line.
                </p>

                <h1>
                    What is the Git and GitHub?
                </h1>

                <p>
                    So about now, if you have never heard of Git or GitHub before, you are probably wondering what the
                    heck these things even are. So, let's get some things straight: </p>
                <dt>Git</dt>
                <dd>Git is a distributed version-control system for tracking changes in any set of files, originally
                    designed for coordinating work among programmers cooperating on source code during software
                    development. Its goals include speed, data integrity, and support for distributed, non-linear
                    workflows.</dd>

                <dt>GitHub</dt>
                <dd>GitHub, Inc. is a provider of Internet hosting for software development and version control using
                    Git. It offers the distributed version control and source code management functionality of Git, plus
                    its own features.</dd>
                <h2>
                    From GitHub:
                </h2>
                <blockquote>
                    GitHub GitHubis how people build software
                    We’re supporting a community where more than 56 million* people learn, share, and work together to
                    build software. We’re working hard to build a supportive, welcoming place for users and GitHubbers
                    alike.

                </blockquote>
                <p>
                    Simply put, Git is a system used to stay up to date, manage and track versions of any project or
                    source code. Any changes made with git commands often lead to version changes and Git is a great way
                    to not get lost in many updates to your project. GitHub, on the other hand, is a service that allows
                    developers to host Git repositories over the cloud.
                    <dt>Git Repositories</dt>
                    <dd>Repositories in GIT contain a collection of files of various different versions of a Project.
                        These files are imported from the repository into the local server of the user for further
                        updations and modifications in the content of the file.(Geeks for Geeks)</dd>

                </p>

                <h2>
                    Installing Git onto your Command Line </h2>
                <p>
                    Now that we have a decent understanding of Git, let's figure out how to install it on our system's
                    commandline. Fortunately, Git is supported on all of Windows, MacOS and Linux/Unix You have some
                    options here. For this example, I will be downloading Git on my Windows PC via the Ubuntu terminal.
                    You can either go onto Git's site and download it straight from there, or simply download it from
                    the command line; I will be doing the latter. For this method, simply type out the following command
                    onto the command line: </p>

                <pre>
           sudo apt innstall git
           </pre>
                <p>
                    This command will trigger a download and once it is completed, you will have git installed on your
                    system. We can then type 'git' to get an overview of everything git has to offer. Refer to the
                    picture below to get a brief understanding of all the git commands.
                </p>

                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/git2.png" alt="Your Name" />

                </Row>
                <h1>
                    Interacting with a GitHub Repositiry from the CLI
                </h1>

                <p>
                    Ok so we now know what Git is, what GitHub is and how to install Git on our system, now let's see
                    how we can interact with a GitHub repository from our command line interface using git commands.
                    There are two main ways of interacting with a GitHub repository: pulling data from the repository to
                    your device and pushing data from your device to the GitHub repository. Luckily, git commands make
                    these tasks super easy. </p>

                <h2>
                    Pulling information from a repository
                </h2>

                <p>
                    Let's say we want to copy a GitHub repository from being hosted on the cloud onto our device. This
                    process is known as cloning a repository. To start things off, make sure you are in the correct
                    place where you want the repository to be cloned. I will save it on my D: drive. All we have to do
                    is type in the following command:
                </p>
                <pre>
                git clone (LINK TO THE REPOSITORY)
                </pre>

                <p>
                    Simply paste the link of the repository you want to clone, I will use this basic Node app repositiry
                    I have on my GitHub account:
                </p>
                <pre>
                git clone https://github.com/dec98524/myNode
                </pre>
                <p>
                    ...and just like that, you have copied an entire GitHub repo onto your local device! But lets say
                    someone updates the code and you want the newest version on your device, well, simply use the pull
                    command. Type the following into your command line:
                </p>

                <pre>
                git pull https://github.com/dec98524/myNode
                </pre>
                <p>
                    This will update your current files to match whatever is on the repository on GitHub. Note: if the
                    repositoiry is private, you will need to confirm that you are the owner by logging into GitHub from
                    your command line.
                </p>


                <h2>
                    Pushing information to a repository
                </h2>
                <p>
                    Ok, so now we know how to grab information from a GitHub repository and put it on your local device.
                    But what if we want to take the information from our local device and update the repository on
                    GitHub? Well, it's a very similar process to pulling. For this example, we are initializing a brand
                    new repo. First we have to initialize our repository (assure you initialize from the root folder).
                    Next we want to link our code with the repo on GitHub using the URL. Next, we will add any changes
                    we have made.</p>

                <pre>
                 git init<br></br>
                 git remote add origin https://github.com/dec98524/myNode.git   
                 </pre>

                <p>
                    Whenever we make any changes to any files and we want to push them, we have to do a few things.
                    First we will use a command to add all the changes. Next we will commit those changes, along with a
                    comment. While a blank comment will result in a default update message, it is always good practice
                    to comment what you are doing when you commit. After committing, we will specify which branch we
                    want to apply those changes to and push them. To do all this, enter the following commands:
                </p>
                <pre>
               git commit -m "first commit"<br></br> 
               git branch -M main<br></br>
               git push -u origin main
               
                 </pre>
                <p>
                    Once all of these commands are entered, you have successfully pushed code from your local device to
                    GitHub!
                </p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/git3.png" alt="Your Name" />
                    <img className="img-fluid" src="blogimages/misc/git4.png" alt="Your Name" />
                </Row>
        </div>
    </Container>
</div>
);
}

export default Blog12;