import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import App from './App';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import './Components/Main.css';




ReactDOM.render(
  <React.StrictMode>
    <Header/>

    <Footer/>
  </React.StrictMode>,
  document.getElementById('root')
);