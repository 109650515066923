import {Helmet} from "react-helmet";
import Title from '../../Components/Title/Title';
// import Jumbo from '../../Components/Jumbo/Jumbo';

import BlogsHomepage from '../../Components/Blogs/Homepage';
// import Socials from '../Components/Socials/Socials';

export default function Blogs(){
return(
<div>
<Helmet>
        <title>David Castaneda | Blogs</title>
        <meta name="description" content="Nested component" />
    </Helmet>
    <div className="blueBox">
        <Title />
    </div>
    {/* <Jumbo/> */}
    <BlogsHomepage />
</div>
)
}

