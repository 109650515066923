// import Title from '../Components/Title/Title';
import Contacts from '../Components/Contact/Contact';
import Socials from '../Components/Socials/Socials';

import {Helmet} from "react-helmet";

export default function Contact(){
    return(
        <div>
             <Helmet>
        <title>David Castaneda | Contact</title>
        <meta name="description" content="Nested component" />
    </Helmet>

             <Contacts/>
             <Socials/>
        </div>
    )

}