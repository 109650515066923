import './MyResume.css';
import '../Main.css';
import { Row, Col, Container } from 'react-bootstrap';
import LazyHero from 'react-lazy-hero';
import {Link} from "react-router-dom";



function MyResume () {
return (
<div>

  <LazyHero parallaxOffset="20" // isFixed="false" // isCentered="false" transitionTimingFunctio="ease-in-out"
    minHeight="70vh" color="black" opacity="0.6" imageSrc="/resumeHero.png">
    <h3 className="whiteh3 heroFont">Resume</h3>
    <p className="power">Get to know me!</p>
  </LazyHero>


  <Container className='greyBox'>
    
  <Row className="spacer justify-content-center resume-link">
      Looking for a concise, single page version of my resume? &nbsp; <a href="/DavidCastanedaResume.pdf" target="_blank"> Click Here!</a>
    </Row>


    <Row className="spacer justify-content-center">
      <Col lg={3}>
      <div className="">
        <img className="img-thumbnail bioPic" src="/david.png" alt="Headshot of David Castaneda" />
      </div>
      </Col>
      <Col lg={8} className="">
      <h1 className="quoteIcon"><i class="fa-regular fa-address-card"></i> About David:</h1>
      <p className='textBox'>
        A hard-working, ambitious and dependable individual. Experienced in the food industry, customer service and
        retail operations. Always eager to elevate in the workforce and expand my skill set. A friendly person, who
        loves interacting and getting to know people of all kinds. Interested in IT, web development, and cloud
        computing.
      </p>
      </Col>
    </Row>



    <Container fluid className="redPattern">
    <h3 className="center whiteh3 heroFont">Skills</h3>
    <p className="center power">Some things I know...</p>
</Container>

    <Row className="spacer justify-content-start">
    <h2 className='myH1'>My Skills</h2>
    </Row>

    <Row className='justify-content-center'>
      <Col lg={4}>

      <ul class="resumelinks">
        <li><i class="fa-solid fa-language"></i> Bilingual</li>
        <li><i class="fa-solid fa-clock"></i> Time Management</li>
        <li><i class="fa-solid fa-file-excel"></i> Microsoft Excel</li>
        <li><i class="fa-solid fa-terminal"></i> Command Line</li>
      </ul>
      </Col>

      <Col lg={4}>

      <ul class="resumelinks">
        <li><i class="fa-brands fa-git-alt"></i> Git Actions</li>
        <li><i class="fa-brands fa-html5"></i> HTML </li>
        <li><i class="fa-brands fa-css3-alt"></i> CSS</li>
        <li><i class="fa-brands fa-js"></i> JavaScript</li>
      </ul>
      </Col>

      <Col lg={4}>

      <ul class="resumelinks">
        <li><i class="fa-brands fa-bootstrap"></i> Bootstrap</li>
        <li><i class="fa-brands fa-node-js"></i> Node.js</li>
        <li><i class="fa-brands fa-react"></i> React</li>
        <li><i class="fa-brands fa-aws"></i> AWS</li>

      </ul>
      </Col>
    </Row>


  </Container>

  {/* Interests */}
  <Container className="spacer greyBox">
  <Container fluid className="orangePattern">
    <h3 className="center whiteh3 heroFont">Interests</h3>
    <p className="center power">Fields that I would love to pursue a career in</p>
</Container>
    <Row>
      <h2 className='myH1'>Interests</h2>
    </Row>

    <Row className="">
      <Col lg={4}>

      <h3>Web Development</h3>
      </Col>
      <Col lg={8}>
      <p className='textBox'> I would say my main interest passion when it comes to I.T is web development. I enjoy the creative freedom of web development and the countless open-source frameworks made by other developers. With web development, I am looking forward to creating appealing and interactive websites for both myself and others, building helpful sites and applications that users can use daily and working on personal projects such as a sports blog and small games.</p>
      </Col>
    </Row>

    <Row className="">
      <Col lg={4}>

      <h3>Cloud Computing</h3>
      </Col>
      <Col lg={8}>
      <p className='textBox'>As cloud computing continues to rise in popularity, my interest in pursuing a career in it increases. Being able to take advantage of all the features these services, such as AWS, have to offer has opened many avenues for us as I.T students/professionals. When it comes to Cloud Computing, I look forward to deploying websites/projects/applications globally, saving money by configuring servers based on what I need and eliminating the headaches that come with on-premise practices </p>
      </Col>
    </Row>


    <Row className="">
      <Col lg={4}>

      <h3>E-commerce</h3>
      </Col>
      <Col lg={8}>
      <p className='textBox'> Combining Cloud Computing and Web Development is what generates my interest of E-Commerce. One thing I love is being able to purchase anything from the comfort of my home, and getting it within days, and sometimes even hours. I strongly believe Brick and Mortar will become a thing of the past and that E-commerce will reign supreme very soon. Something along the lines of designing a fully functioning cloud based E-commerce website interests me very much.</p>
      </Col>
    </Row>
  </Container>



  {/* Education */}

 
  <Container className="">
  <Container fluid className="greenPattern">
    <h3 className="center whiteh3 heroFont">Education</h3>
    <p className="center power">My academic journey</p>
</Container>
    <Row>
      <h2 className='myH1'>Education</h2>
    </Row>

    <Row className="spacer">
      <Col lg={4}>

      <h3>Culver City High School</h3>
      <small>2013-2017</small>
      </Col>
      <Col lg={8}>
      <ul>
        <li>High School Diploma, 2017</li>
        <li>Seal of Biliteracy</li>
        <li>Perfect Attendance</li>
        <li>3.00 GPA</li>
      </ul>
      <Row>
        <p className='textBox'>
          My time at Culver City High School was something I did not appreciate until it was over. While it started off
          rough, I was able to persevere through the adversity and earn my diploma. I never missed a day of school, and
          was able to earn the California Seal of Biliteracy after the AP Spanish test.
        </p>
      </Row>
      </Col>
    </Row>

    <Row className="spacer">
      <Col lg={4}>

      <h3>CSUN</h3>
      <small>2017-2021</small>
      </Col>
      <Col lg={8}>
      <ul>
        <li>Computer I.T B.S, 2021</li>
        <li>Sustainability Cohesive Package</li>
        <li>3x Dean's List</li>
        <li>3.30 GPA</li>
      </ul>
      <Row>
        <p className='textBox'>
          My four years at CSUN were some of the most difficult, yet enjoyable years of my life. I always wanted to
          pursue a future in tech, and earning my B.S in IT has set me up for that. In addition, I also chose to study
          Sustainability alongside CIT, which opened my eyes to the impact various things have on our planet. I was able
          to make Dean's List three times throughout my time at CSUN. Aside from my studies, CSUN showed me the
          importance of networking and time management.
        </p>
      </Row>
      </Col>
    </Row>
  </Container>


  {/* Work */}
  <Container className="greyBox">
  <Container fluid className="bluePattern">
    <h3 className="center whiteh3 heroFont">Work Experience</h3>
    <p className="center power">Jobs I have worked</p>
</Container>
    <Row>
      <h2 className='myH1'>Work Experience</h2>
    </Row>

    <Row className="spacer">
      <Col lg={4}>

      <h3>Subway</h3>
      <small>2019-2020</small>
      </Col>
      <Col lg={8}>
      <ul>
        <li>POS system</li>
        <li>Inventory/Stocking/Labeling</li>
        <li>Cleaning and Food Prep</li>
        <li>Customer Service</li>
      </ul>
      <Row>
        <p className='textBox'>
          While reluctant at first, taking a job at Subway was a pretty good experience. Being on a college campus, fast
          pace was normal pace here and my tasks varied from day to day. Some days I would make sandwiches, some days I
          would operate the POS system and ring customers up. Other days I would be in charge of prepping, labeling and
          dating food to ensure we were always stocked for rushes, and some days would just be pure cleaning. Given the
          environment, this job was a team effort and taught the value of teamwork.
        </p>
      </Row>
      </Col>
    </Row>

    <Row className="spacer">
      <Col lg={4}>

      <h3>Jordan Belfort</h3>
      <small>One Month Internship</small><br></br>
      <small>March 2021</small>
      </Col>
      <Col lg={8}>
      <ul>
        <li>Various Web Development duties</li>
        <li>Using Shopify & React to make responsive web pages</li>
        <li>Using GitLab to collaborate on projects with co-workers</li>
        <li>UX/UI</li>
      </ul>
      <Row>
        <p className='textBox'>
          While applying for jobs in college, I saw an opportunity to help the Wolf of Wallstreet himself: Jordan
          Belfort. His business was looking for short term assistance on the development team to help elevate and
          maintain his website. During my time there, I would help clean up the site for his company. While my tenure
          here was short, it gave me a glimpse at what I would love to do in the future.
        </p>
      </Row>
      </Col>
    </Row>

    <Row className="">
      <Col lg={4}>

      <h3>Curacao</h3>
      <small>2021-Present</small>
      </Col>
      <Col lg={8}>
      <Col lg={6}>
      <ul>
        <li>Inventory Control</li>
        <li>Excel/Macros</li>
        <li>Customer Service</li>
        <li>Credit Assistance</li>
      </ul>
      </Col>
      <Row>
        <p className='textBox'>
          Curacao has given me the opportunity to grow as professional. Being a new store, I was able to develop strong
          relationships with co-workers that stand strong to this day, and be a part of a team, that made this store
          come to fruition. I began as a credit representative at Curacao. This consisted of many things, but it was
          mainly walking clients through the credit process, establishing customer relationships to make sales and
          customer service ranging from application assistance to product inquiries. I enjoyed the position, but it came
          to an end when my manager approached me to see if I was interested in changing positions.
        </p>
      </Row>
      <Row>
        <p className='textBox'>
          At Curacao, every store has a person who controls inventory for the entire store, the Inventory Agent. My
          manager offered me the position for the new store, and I jumped on it. My responsibilities were now
          drastically different and of much more importance. The inventory process consisted of scanning every product
          in a certain department, uploading my scanner to a database and auditing. In the event that something was
          missing, I would investigate and report all my discoveries to my manager. I have also learned many warehouse
          skills, such as using forklifts, scissor lifts, pallet jacks, unloading trucks, shipping products and a lot
          more.
        </p>
      </Row>
      </Col>
    </Row>
  </Container>

  {/* CONTACT */}

  <Container className='greyBox'>
  <Container fluid className="purplePattern">
    <h3 className="center whiteh3 heroFont">References</h3>
    <p className="center power">Any questions? Contact these guys</p>
</Container>
    <Row className="justify-content-start spacer myH1">
      <h1>References</h1>
    </Row>

    <Row className='justify-content-center'>
      <Col lg={4}>
      <h2>Claudia Polanco</h2>
      <p>Curacao Operations Manager<br></br>
        <i class="fa-solid fa-phone"></i> (661) 886-2158<br></br>
        <i class="fa-solid fa-at"></i> claudiap@icuracao.com
      </p>

      </Col>

      <Col lg={4}>
      <h2>Oscar Gonzalez</h2>
      <p>Curacao General Manager<br></br>
        <i class="fa-solid fa-phone"></i> (562) 392-2194<br></br>
        <i class="fa-solid fa-at"></i> gonzalezo@icuracao.com
      </p>
      </Col>

      <Col lg={4}>
      <h2>Luis Lopez</h2>
      <p>Curacao Inventory Manager<br></br>
        <i class="fa-solid fa-phone"></i> (213) 332-1442<br></br>
        <i class="fa-solid fa-at"></i> luisl@icuracao.com
      </p>
      </Col>
    </Row>
    <Row className='spacer justify-content-center'>
<i>Want to reach out? <Link to="/contact">Contact Me!</Link></i>
</Row>

  </Container>
</div>
);
}

export default MyResume;