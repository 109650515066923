import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog19 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #19</h1>
                <p>By: David Castaneda</p>
                <p>Date: 4/9/2021</p>


                <h1>
                Introduction - EmailJS
            </h1>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/emailjs.png" alt="Your Name" />

                </Row>
                <p>
Often times on websites, it is common for you to run across a contact page where you can find various contact information about the person, company or service. Contact information you may find includes a phone number, an address, social media, and possibly the most important, an email address. In most cases, email is the most effisient way to get ahold of a person or company. While you can just paste your email address to your contact page, a more efficient and user friendly way to do this is to have an email form on your page. That way, the user can simply enter there name, email and message and send you a message right away without leaving your site. In this blog, I will dive into the world of EmailJS.

</p>


<p>
  There are many email frameworks available out there, so let's see what EmailJS has to offer:      
</p>
<h2>
    From the devs at EmailJS: 
</h2>
<blockquote>
Send Email Directly From JavaScript,
No server code needed. Focus on things that matter! Choose from a wide variety of email services.
We support both transactional email services (Mailgun, Mailjet, Mandrill, SendinBlue, SendGrid, Amazon SES and Postmark) and personal email services (AOL, Gmail, FastMail, iCloud, Mail.ru, Outlook, Yahoo, Yandex and Zoho). Easily build your own template. Choose the tool most suitable for you: text editor or code editor. Templates are parameterized, so that you can further customize them via JavaScript. Add our JavaScript SDK, and start sending emails!
</blockquote>

<p>
In a nutshell, EmailJS is a JavaScript framework that allows us to send emails from our application 100% through JavaScript, and without the need for server side code. While in most cases, sending email would require some sort of server side code to convey the message, such as PHP or Node.js, EmailJS allows us to handle this completely on the front end. In addition, EmailJS is compatible with a number of popular online email services such as Gmail, Yahoo and Mailgun.    
</p>

<h1>
Getting Started With EmailJS
</h1>

<h2>Making an Account</h2>

<p>Once you are ready, head over to <a href="https://www.emailjs.com/">https://www.emailjs.com/</a> and make an account. Once you have made an account, you will want to go to the 'Email Services' tab, for this example, select Gmail. Here yopu will connect your Gmail account, to which you will recieve emails from your form. Next, you will go to the 'Email Templates' tab and create a new template. This can be customized however you would like, here is how I did mine:</p>

<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/emailjs1.png" alt="Your Name" />

                </Row>

<p>This will send me an email and the message will contain Who sent it, the email I can reply to and the message itself. Another thing to note is the variables in the curly braces. These are dynamic values that will be taken streaight from our JavaScript code. What ever input our user submits will be read and displayed dynamically. Once you haved this all set up, you are ready to add this to your project</p>


<h2>Installing EmailJS Locally</h2>
<p>
For this section, will be going over the process and steps that I took to add a functional Email form to my personal website. While the framework works with any JavaScript project, my website is built on React so I wiull show how I was able to implement this service on a React project. So, to get things started, we have to actually install the framework itself before we can get to use it. Depending on the package manager you are using, these steps may very. In my case, since it is a React project, I am using NPM. To download EmailJS with NPM, type in the following command on your terminal:</p> 
<pre>
npm install emailjs-com
</pre>

<p>
This will install the package and add it to the dependencies section of your package.json file. We now have access and are ready to use what this package has to offer. Let's go about implementing it to our project. In my case, I have a page dedicated to contac information named 'Contact' so I will be adding my form on that page. There are two steps we need to take here. First I will add a form using Bootstrap, which I am using across my website. Next, I will add the JavaScript to make the form functional. To start things off, I will make a jsx file in my src/Components directory named 'contactForm.jsx'. On that file, add the following code: </p>

<pre>
import emailjs from 'emailjs-com'<br></br><br></br>

function sendEmail(e)&#123;<br></br>
e.preventDefault();<br></br><br></br>

emailjs.sendForm(<br></br>
    process.env.REACT_APP_EMAILJS_SERVICE_ID,<br></br> 
    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,<br></br>
     e.target, <br></br>
     process.env.REACT_APP_EMAILJS_USER_ID)<br></br><br></br>
.then((result) =&gt; &#123;<br></br>
console.log(result.text);<br></br>
&#125;, (error) =&gt; &#123;<br></br>
console.log(error.text);<br></br><br></br>

&#125;);<br></br>
e.target.reset();<br></br>
&#125;
</pre>

<p>
A few things are going on here. First we imported the package itself so we are able to use it. We then initialize a function that will send the email messafe to our account. We then establish our connection to the service by linking our account. For this, you will need to go to the dashboard and identify three things: The service ID, the template ID and your user API key. These should be easy to find, just navigate to where you created each thing. As for the API Key, go to the 'Integrations' tab. I used an environment variable here to hide and proctect my keys for when I publish them publically to something like GitHub. Next, We take the measures to tell the user what the status of their message is; if it was successful, the console would log a success message and vice versa. Finally, we have a method to reset the form when you submit.
</p>

<h2>Making the Form</h2>
<p>
Like I said before, I will be using React-Bootstrap to make my form. In the form we need to link the function we just wrote and trigger it when the user submits the form. In your same file, type the following code: 
</p>

<pre>

import &#123;Form, Button&#125; from 'react-bootstrap'
&lt;Form className=&quot;spacer contact-form&quot; onSubmit=&#123;sendEmail&#125;&gt;<br></br>
    &lt;Form.Group&gt;<br></br>
      &lt;Form.Label&gt;Your Name&lt;/Form.Label&gt;<br></br>
      &lt;Form.Control type=&quot;text&quot; name=&quot;name&quot; placeholder=&quot;Name&quot; required /&gt;<br></br>
    &lt;/Form.Group&gt;<br></br><br></br>

    &lt;Form.Group&gt;<br></br>
      &lt;Form.Label&gt;Your Email&lt;/Form.Label&gt;<br></br>
      &lt;Form.Control type=&quot;email&quot; name=&quot;user_email&quot; placeholder=&quot;Email&quot; required /&gt;<br></br>
    &lt;/Form.Group&gt;<br></br><br></br>

    &lt;Form.Group&gt;<br></br>
      &lt;Form.Label&gt;Subject&lt;/Form.Label&gt;<br></br>
      &lt;Form.Control type=&quot;text&quot; name=&quot;subject&quot; placeholder=&quot;Subject&quot; required /&gt;<br></br>
    &lt;/Form.Group&gt;<br></br><br></br>



    &lt;Form.Group&gt;<br></br>
      &lt;Form.Label&gt;Your Message&lt;/Form.Label&gt;<br></br>
      &lt;Form.Control as=&quot;textarea&quot; rows={10} name=&quot;message&quot; placeholder=&quot;What's on your mind??&quot; required/&gt;<br></br>
    &lt;/Form.Group&gt;<br></br><br></br>


    &lt;Button variant=&quot;primary&quot; type=&quot;submit&quot; value=&quot;Send&quot;&gt;<br></br>
      Submit<br></br>
    &lt;/Button&gt;<br></br><br></br>

  &lt;/Form&gt;<br></br>
</pre>

<p>
A few things here: we have made a fully functional and responsive form using Bootstrap. We made fields for Name, Email, Subject and Message. In those fields, we gave them a name, which is used to dynamically push the data to EmailJS. We made it so all these fields are required, meaning the form cannot be submitted if there is one missing. Finally, we added made it so the 'sendEmail' function will trigger once the form is submitted.    
</p>

<h2>Sending an Email</h2>

<p>We are finished setting up and configuring our code, now lets test it. Let's send ourselves a sample email. In the form, I will type the following data and submit the form:</p>
<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/emailjs2.png" alt="Your Name" />

                </Row>

                <p>
                    And if everything is configured, and goes right, you should recieve an email in your inbox similar to this! If you would like to try it yourself, <Link to="/contact">click here!!!</Link>
                </p>

                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/emailjs3.png" alt="Your Name" />

                </Row>
        </div>
    </Container>
</div>
);
}

export default Blog19;
