import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog3 () {
return (
<div>

<Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #3</h1>
                <p>By: David Castaneda</p>
                <p>Date: 8/27/2020</p>


                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/glitch.png" width="300" height="300" alt="Your Name" />
                    <img className="img-fluid" src="blogimages/misc/mdn.png" width="300" height="300" alt="Your Name" />

                </Row>
                <Row className="spacer justify-content-start">
                    <h2>Intro - Welcome to my first Blog!</h2>
                    <p>This semester I am taking both CIT 480 and COMP 484. The classes have some overlapping content
                        and stuff I learn in one class often helps me with assignments in the other class. This week in
                        COMP 484 we learned about the fundamentals of HTML. Our class was given a very basic website
                        about the history of HTML, as well as some references that can help with your coding. Some of
                        these references include W3C, Mozilla Developer Network and Markup Validation Service.</p>
                </Row>
                <Row className="spacer justify-content-start">
                    <h2>Working on the assignment</h2>
                    <p>We were tasked with taking the barebones site and formatting it so that it is reader friendly.
                        This includes separating blocks of text and formatting them in paragraphs. To do this, you use
                        the p tag. Additional things that can be done in order to make the website reader friendly are
                        adding headers h1, line breaks br and lists ol or ul. I was able to take a website with
                        essentially a huge block of text and format it so that certain parts relating to one another are
                        grouped together. I made sure to emphasize key terms and important bits of information. Lastly,
                        I played around with the CSS stylesheet. This is used to customize the appearance of the website
                        and can be helpful in making the website more appealing to the eye. Overall, I was proud of the
                        transformation of the site.</p>
                </Row>

        </div>
    </Container>
</div>
);
}

export default Blog3;