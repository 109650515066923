import FAQs from '../Components/FAQs/FAQs';


import {Helmet} from "react-helmet";

export default function FAQ(){
    return(
        <div>
             <Helmet>
        <title>David Castaneda | Frequently Asked Questions</title>
        <meta name="description" content="Nested component" />
    </Helmet>

             <FAQs/>
        </div>
    )

}