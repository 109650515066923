import Personal from '../Components/Personal/Personal';
import {Helmet} from "react-helmet";

export default function PersonalLife(){
    return(
        <div>
             <Helmet>
        <title>David Castaneda | Personal</title>
        <meta name="description" content="Nested component" />
    </Helmet>
    <Personal />
        </div>
    )

}