import './Title.css';
import '../Main.css';
import { Row, Col, Container } from 'react-bootstrap';
import 'animate.css';




function Title () {
return (
<div>
  <Container>
   <Row className="justify-content-center">
      <Col lg={7}>
      <img class="img-fluid" src="/logo.png" alt=""></img>
      <h3 class="spacer center">Powered by <i class="animate__animated animate__zoomInDown fab fa-react"></i></h3>
      </Col>
    </Row>
  </Container>
</div>
);
}

export default Title;