import '../Main.css';
import './Header.css';
import * as ReactBootStrap from 'react-bootstrap';
import {Route, BrowserRouter as Router, Link, Switch} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Home from "../../Pages/Home";
import Blogs from "../../Pages/Blogs/Blogs";
import Resume from "../../Pages/Resume";
import Personal from "../../Pages/Personal";
import Contact from "../../Pages/Contact";
import FAQ from "../../Pages/FAQ";
import TRIKL from "../../Pages/TRIKL";

//ALL BLOGS
import Blog0 from "../../Pages/Blogs/Blog0";
import Blog1 from "../../Pages/Blogs/Blog1";
import Blog2 from "../../Pages/Blogs/Blog2";
import Blog3 from "../../Pages/Blogs/Blog3";
// import Blog4 from "../../Pages/Blogs/Blog4";
// import Blog5 from "../../Pages/Blogs/Blog5";
import Blog6 from "../../Pages/Blogs/Blog6";
import Blog7 from "../../Pages/Blogs/Blog7";
import Blog8 from "../../Pages/Blogs/Blog8";
// import Blog9 from "../../Pages/Blogs/Blog9";
import Blog10 from "../../Pages/Blogs/Blog10";
// import Blog11 from "../../Pages/Blogs/Blog11";
import Blog12 from "../../Pages/Blogs/Blog12";
import Blog13 from "../../Pages/Blogs/Blog13";
import Blog14 from "../../Pages/Blogs/Blog14";
import Blog15 from "../../Pages/Blogs/Blog15";
import Blog16 from "../../Pages/Blogs/Blog16";
import Blog17 from "../../Pages/Blogs/Blog17";
import Blog18 from "../../Pages/Blogs/Blog18";
import Blog19 from "../../Pages/Blogs/Blog19";
import Blog20 from "../../Pages/Blogs/Blog20";
import Blog21 from "../../Pages/Blogs/Blog21";
import Blog22 from "../../Pages/Blogs/Blog22";
import ScrollToTop from '../ScrollToTop';
import NotFound from '../NotFound/NotFound';

function Header () {
return (
<Router>
  <ScrollToTop />
    <div className="header" id="top">
      <div className="wrapper">
      <ReactBootStrap.Navbar className="navColor" expand="lg">
    <Link to="/">
    <ReactBootStrap.Navbar.Brand>
      <img
        src="/haloD.png"
        width="80"
        height="80"
        className="d-inline-block align-center"
        alt="dc logo"
      />
    </ReactBootStrap.Navbar.Brand>
    </Link>
        <ReactBootStrap.Navbar.Brand ><Link to="/">David Castaneda</Link></ReactBootStrap.Navbar.Brand>
        <ReactBootStrap.Navbar.Toggle aria-controls="basic-navbar-nav" />
        <ReactBootStrap.Navbar.Collapse id="basic-navbar-nav">
          <ReactBootStrap.Nav className="mr-auto">

            <ReactBootStrap.Nav.Link>
              <HashLink to="/#home">Home</HashLink>
            </ReactBootStrap.Nav.Link>

{/* 
            <ReactBootStrap.NavDropdown renderMenuOnMount={true} title="Blogs" id="basic-nav-dropdown">
              <ReactBootStrap.NavDropdown.Item>
                <Link to="/blogs">Blogs - Homepage</Link>
              </ReactBootStrap.NavDropdown.Item>
              <ReactBootStrap.NavDropdown.Item>
                <Link to="/blog22">Blog 22</Link>
              </ReactBootStrap.NavDropdown.Item>
                <ReactBootStrap.NavDropdown.Item>
                <Link to="/blog21">Blog 21</Link>
              </ReactBootStrap.NavDropdown.Item>
              <ReactBootStrap.NavDropdown.Item>
                <Link to="/blog20">Blog 20</Link>
              </ReactBootStrap.NavDropdown.Item>
              <ReactBootStrap.NavDropdown.Item>
                <Link to="/blog19">Blog 19</Link>
              </ReactBootStrap.NavDropdown.Item>
              <ReactBootStrap.NavDropdown.Item>
                <Link to="/blog18">Blog 18</Link>
              </ReactBootStrap.NavDropdown.Item>
              <ReactBootStrap.NavDropdown.Item>
                <Link to="/blogs"><b>All Blogs</b></Link>
              </ReactBootStrap.NavDropdown.Item>

            </ReactBootStrap.NavDropdown> */}

            <ReactBootStrap.Nav.Link>
              <Link to="/resume">Resume</Link>
            </ReactBootStrap.Nav.Link>
            <ReactBootStrap.Nav.Link>
              <Link to="/personal">Personal Life</Link>
            </ReactBootStrap.Nav.Link>
            <ReactBootStrap.Nav.Link>
              <Link to="/trikl">TRIKL</Link>
            </ReactBootStrap.Nav.Link>
          </ReactBootStrap.Nav>

          

          <ReactBootStrap.Nav.Link>
          <Link className='linkBg' to="/contact">Contact Me</Link>
          </ReactBootStrap.Nav.Link>

        </ReactBootStrap.Navbar.Collapse>
      </ReactBootStrap.Navbar>
    </div>
    </div>

<Switch>
  <Route path="/" exact component={Home} />
  <Route path="/blogs" exact component={Blogs} />
  <Route path="/resume" exact component={Resume} />
  <Route path="/personal" exact component={Personal} />
  <Route path="/contact" exact component={Contact} />
  <Route path="/faq" exact component={FAQ} />
  <Route path="/trikl" exact component={TRIKL} />
  

  {/* ALL BLOGS*/}

  <Route path="/blog0" exact component={Blog0} />
  <Route path="/blog1" exact component={Blog1} />
  <Route path="/blog2" exact component={Blog2} />
  <Route path="/blog3" exact component={Blog3} />
  <Route path="/blog6" exact component={Blog6} />
  <Route path="/blog7" exact component={Blog7} />
  <Route path="/blog8" exact component={Blog8} />
  <Route path="/blog10" exact component={Blog10} />
  <Route path="/blog12" exact component={Blog12} />
  <Route path="/blog13" exact component={Blog13} />
  <Route path="/blog14" exact component={Blog14} />
  <Route path="/blog15" exact component={Blog15} />
  <Route path="/blog16" exact component={Blog16} />
  <Route path="/blog17" exact component={Blog17} />
  <Route path="/blog18" exact component={Blog18} />
  <Route path="/blog19" exact component={Blog19} />
  <Route path="/blog20" exact component={Blog20} />
  <Route path="/blog21" exact component={Blog21} />
  <Route path="/blog22" exact component={Blog22} />

  <Route path="" component={NotFound} />
  </Switch>
</Router>
);
}

export default Header;