import Blog12 from '../../Components/Blogs/Blog12';
import {Helmet} from "react-helmet";


export default function Blog12Page(){
    return(
        <div>
                        <Helmet>
        <title>David Castaneda | Blog 12</title>
        <meta name="description" content="Nested component" />
    </Helmet>

            <Blog12/>
        </div>
    )

}

