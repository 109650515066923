import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog8 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #8</h1>
                <p>By: David Castaneda</p>
                <p>Date: 10/30/2020</p>

                <h1>Node Package Manager (NPM)</h1>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/npm1.png" alt="Your Name" />
                </Row>


                <h2>Introduction - Node Package Manager (NPM)</h2>

                <p>Last week, my blog consisted of a brief introduction to the backend, JavaScript framework known as
                    Node.js. If you have
                    not yet read Blog 7, or are not familiar with Node.js,
                    <Link to="/blog7">here is a link
                    </Link>to the previous blog. What we will be covering in this blog is a tool that will make using
                    Node.js much less of a headache.
                    This tool is known as Node Package Manager, or NPM for short. In this blog, we will be covering what
                    NPM is, a few of the tools NPM
                    provides for us as programmers and how to access those tools.
                </p>
                <h1>What Is Node Package Manager?</h1>

                <p>npm is a package manager for the JavaScript programming language. It is the default package manager
                    for the JavaScript runtime environment Node.js. You can think of NPM as a collection of modules in
                    JavaScript code that is used to elevate your Node.js programs. NPM hosts thousands of free packages
                    to download and use. Upon downloading Node.js (covered in Blog 7), NPM is automatically included in
                    your download and ready to use on your computer.
                </p>
                <h1>Getting Started - Getting Packages</h1>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/npm2.png" alt="Your Name" />
                </Row>
                <p>As stated before, you automatically get NPM as part of your initial Node.js download, so after
                    installing nOde.js onto your system, no additional steps are needed to use NPM. Now that we know we
                    have access to NPM, how do we access these packages? Simple, simply navigate over to <a
                        href="https://www.npmjs.com/">www.npmjs.com</a> to start things off. You'll know you're in the
                    right place when you see a page like the one in the image above. This website allows us to easily
                    search for, and install packages that will make coding seem much more simple. </p>
                <p>From NPM:</p>

                <blockquote cite="www.npmjs.com">
                    Relied upon by more than 11 million developers worldwide, npm is committed to making JavaScript
                    development elegant, productive, and safe. The free npm Registry has become the center of JavaScript
                    code sharing, and with more than one million packages, the largest software registry in the world.
                    Our other tools and services take the Registry, and the work you do around it, to the next level.
                </blockquote>

                <h2>Searching for and Installing Packages</h2>
                <p>There are hundreds of packages that you as a programmer have at your disposal thanks to NPM. To find
                    one that you would like to use, simply use the search bar and search for something related to your
                    project. For this example, we will be going over the package known as Nodemon. You should see a page
                    with full documentation of the package, instructions on how to install it and what it's all about.

                    From the Developers of Nodemon: </p>
                <blockquote cite="https://www.npmjs.com/package/nodemon">
                    nodemon is a tool that helps develop node.js based applications by automatically restarting the node
                    application when file changes in the directory are detected.
                </blockquote>

                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/nodemon.png" alt="Your Name" />
                </Row>

                <p>
                    When using Node.js for server-side programming, in order to test your program on a live server, you
                    manually have to type in the following command:</p>

                <pre>node NAMEOFFILE.js</pre>
                <p> Upon doing this, you will be able to run your program and test it. The problem with this comes when
                    you want to make changes to your code; you would have to terminate your process, make changes to
                    your file, save those changes and run the command again to test your new program. As you could
                    Imagine, this could get rather annoying and inconvinent. Nodemon allows a user to run an alternate
                    command to test a server. The command is simply:</p>
                <pre>nodemon NAMEOFFILE.js</pre>

                <p> Running this command, as opposed to the original way, automatically restarts the server to take in
                    any new changes you may have made in your file. You do not need to terminate and reinitaite your
                    server, it's automatic. To install Nodemon, type in the following command: </p>
                <pre>npm install -g nodemon</pre>
                <p> This should install nodemon globally on your device and will make it ready to use across any other
                    project! NOTE: It might be a good idea to run the command:</p>
                <pre>npm init</pre>
                <p> This will create a JSON file for all your packages and can be quite helpful when installing multiple
                    packages. You now have installed a package using NPM!
                </p>

                <h1>Other Helpful Packages</h1>
                <p>
                    When working on some stuff for class, I used a wide variety of packages. Here are some helpful
                    packages I used that allowed me to make some simple apps:</p>

                <dl>
                    <dt>Express</dt>
                    <dd>
                        <Row className="spacer justify-content-center">
                            <img className="img-fluid" src="blogimages/misc/npm3.png" alt="Your Name" />
                        </Row>
                        Express is almost a must when you are developing apps that deal with routing, requests/responses
                        and server-side logic. This is the very first package you should download when making any sort
                        of app.
                    </dd>
                    <dt>EJS - Embedded JavaScript</dt>
                    <dd>
                        <Row className="spacer justify-content-center">
                            <img className="img-fluid" src="blogimages/misc/npm4.png" alt="Your Name" />
                        </Row>
                        EJS allows you to implement dynamic code on the front end of your project. This can be helpful
                        when dealing with HTML code that needs to be changed a lot. Using EJS, you wont have to
                        constantly go into your html file and manually change it everytime.
                    </dd>
                    <dt>Bcrypt</dt>
                    <dd>
                        <Row className="spacer justify-content-center">
                            <img className="img-fluid" src="blogimages/misc/npm5.png" alt="Your Name" />
                        </Row>
                        My project involves a login system and with that comes passwords. Bcrypt is a great encryption
                        oriented package that allows you to obtain user data, such as user ID's and passwords, and
                        easily manage them while keeping them 100% safe and encrypted. This package can be very helpful
                        when dealing with sensitive information.
                    </dd>
                </dl>
                <h2>Final Thoughts</h2>
                <p>After learning the beauty of Node.js last week, and learning the power of Node Package Manager (NPM)
                    this week, my backend development skills have improved. I now have the ability to create some
                    applications that have both a clean frontend and efficient backend. Having all the tools that NPM
                    provides, developing an app has never been easier. I am eager to see what other packages NPM has to
                    offer and am very glad there is such an active community that provides so many helpful tools to
                    aspiring developers such as myself. </p>

        </div>
    </Container>
</div>
);
}

export default Blog8;