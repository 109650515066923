import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog7 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #7</h1>
                <p>By: David Castaneda</p>
                <p>Date: 10/23/2020</p>

                <h1>Node.js</h1>
                <Row className="spacer justify-content-center">
                    <img className="articlePic" src="blogimages/misc/nodejs.png" alt="Your Name" />
                </Row>

                <h2>Introduction - Assignments Worked On</h2>

                <p>This week I was introduced to backend development in my COMP 484 class. As stated in my last blog, I
                    was assigned a
                    final group project where myself and 4 other group members were tasked with creating a web app using
                    a unique set of
                    frameworks. Last week went over the front-end, css related framework known as Bootstrap. If you have
                    not yet been introduced
                    to Bootstrap,
                    <Link to="/blog6">my last blog</Link> where I go over the
                    basics of Bootstrap and how I was able to implement it into my personal website. Along with
                    Bootstrap, my group decided to
                    use the framework known as Node.js to handle all of our backend tasks on the server side.
                </p>



                <h1>What Is Node.js?</h1>

                <p>Node.js is an open-source, cross-platform, back-end, JavaScript runtime environment that executes
                    JavaScript code outside a web browser.
                    Using Chrome's powerful v8 engine, Node.js allows us to use JavaScript as a backend programming
                    language, making it possible to run
                    server-side code completely on JavaScript. Node.js is completely free and runs on various platforms,
                    such as Windows, Mac OS, Linux and Unix.
                    Node.js has the ability to generate dynamic page content, it can create, open, read, write, delete,
                    and close files on the server,
                    it can collect form data and also add, delete, modify data in your database.
                </p>


                <h1>Getting Started - Installing Node</h1>

                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/node2.png" alt="Your Name" />
                </Row>

                <p>Downloading and installing Node.js is very simple. To get Node.js on your system, simply head over to
                    <a href="https://nodejs.org/en/download/">https://nodejs.org/en/download/</a> and select your
                    corresponding operating system. In my case, I have
                    a Macbook Air, so I selected the MacOS installer option. Upon clicking, a download should begin and,
                    once finished, you should have Node.js installed.
                    A quick way to do this is to go on your command line, in my case the terminal (Windows users can use
                    cmd or powershell) and typing in the following command:</p>
                <br></br>
                <pre>
                    node -v
                </pre><br></br>
                <p>
                    This should output the current version of Node.js installed on your system. If you see a version
                    number, you are good to go.
                </p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/node3.png" alt="Your Name" />
                </Row>

                <p>
                    To start things off, we will go over how to set up a very basic Node app. Let's start by creating a
                    file called firstnode.js. Inside this file, we will write a program that will initialize our server
                    via localhost and we will also include a short message. To start we must understand how the Node.js
                    framework operates. Node, contains a massive library of pre made packages, which are scripts
                    included when you download Node.js. To reference these packages, you must include them in your file.
                    There are many, but one example is http. This will allow us to create a server via localhost. To
                    include it, we must type the following:</p>
                <pre>
                    const http = require('http');
                </pre>
                <p>Once included, we as programmers will have access to the http package. Now that we have access to it,
                    we can set up our server. Type the following code into your file:</p>

                <pre>
                http.createServer(function (req, res) &#123; <br></br>
                        res.writeHead(200, &#123; 'Content-Type': 'text/plain'&#125; );<br></br>
                        res.end('Hello!');<br></br>
                      
                        &#125; ).listen(8080);
                </pre>
                <p> We are using the http object to intialize our device as a server using the method .createServer. We
                    are then passing a function with the parameters of req and res which are the responses and requests
                    the server and browser are sending to one another. Then we establish a status code and what type of
                    content we are sending with the .writeHead method. In this case, a 200 status code for a successful
                    process and just some plain text. res.end contains our content, while ending the response process.
                    We set up which port we want our localhost to use with the listen() object, in this case, we will
                    use localhost:8080. Now that we are done with our code, we must run our file. Save everything and
                    type the following in your terminal:</p>
                <pre>
                    node firstnode.js
                </pre>
                <p>You will now be able to open a browswer, and type in http://localhost:8080 and see your new app!</p>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/node4.png" alt="Your Name" />
                </Row>
                <h2>Final Thoughts</h2>
                <p>Although a bit difficult and confusing at first, I really enjoyed learning the basics of Node.js. Up
                    until now, I had mainly used frontend frameworks to build websites, such as Bootstrap. I am glad I
                    was finally able to start learning the importance of backend development. I know that this is just
                    the start, and am eager to see the cool new apps I will be able to build with a combination of
                    frontend and backend framewoks such as Bootstrap and Node.js. One thing I learned is just how much
                    packasges Node.js has available. there are a number of packages you are able to install which
                    provide the tools to make your programming less of a headache. Next week, I will go over some must
                    have packages that Node.js provides and how to install them.</p>


        </div>
    </Container>
</div>
);
}

export default Blog7;