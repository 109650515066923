import Blog13 from '../../Components/Blogs/Blog13';
import {Helmet} from "react-helmet";


export default function Blog13Page(){
    return(
        <div>
                        <Helmet>
        <title>David Castaneda | Blog 13</title>
        <meta name="description" content="Nested component" />
    </Helmet>

            <Blog13/>
        </div>
    )

}

