import './NotFound.css';
import { Link } from "react-router-dom"

const NotFound = () =>{
    return (
       <div className="not-found">
           <h3 className="spacer bounce">Uh oh!</h3>
           <h4>404 - That page Cannot be found</h4>
            <div className="spacer">
           <Link className="btn404" to="/">Homepage</Link>
            </div>
           <img className="pic-404 img-fluid spacer spin" src="/ltbu.gif" alt=""></img>

              </div>
     );

}

export default NotFound;