import './TRIKL.css';
import '../Main.css';
import { Row, Col, Container } from 'react-bootstrap';


function TRIKL () {
return (
<div>
  <div className="greyBox">




    <Container fluid className="greyBox wrapper">
      <Row className="spacer">

      </Row>


      <Row className='justify-content-center'><img alt="TRIKL Word Art"
          className="img-fluid unselectable animate__animated animate__pulse animate__delay-2s fa-regular fa-heart"
          src="/trikl/textTrans.png"></img>
      </Row>

      <Row className="spacer justify-content-center">

        <h2>CAR WASH AND DETAIL</h2>
      </Row>
      <Row className="spacer justify-content-center">

        <p>
          A clean car is better &#10024;
        </p>

      </Row>

      <Row className="spacer">
        <Col lg={3}>
        <div className="">
          <img className="img-thumbnail bioPicClear" src="/trikl/logoTrans.png" alt="TRIKL Logo" />
        </div>
        </Col>
        <Col lg={9}>
        <h1 className='quoteIcon'> About Us:</h1>
        <p className='textBox'>
          TRIKL Car Wash and Detail is an organization founded in 2023. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Incidunt, pariatur officia cupiditate, doloribus vero impedit distinctio dolore expedita
          explicabo, reprehenderit quis ullam possimus cumque vitae omnis et nobis deserunt architecto! Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Atque quis repellendus tempora consequatur, deleniti asperiores,
          dolore sit ad autem nihil reprehenderit ducimus earum quos molestias cumque, adipisci iste recusandae omnis!
        </p>
        </Col>
      </Row>

      <Row>

        <h1>Our Work</h1>
      </Row>
      <Row>
        <p>
          Some cars we have washed!
        </p>
      </Row>

      <Row className='spacer justify-content-center'>
        <h1>Acura Integra A-SPEC</h1>
      </Row>

      <Row className='spacer justify-content-center'>
        <Col lg={4}>

        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\acura1.jpg" />
        </div>
        </Col>
        <Col lg={4}>
        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\acura6.jpg" />
        </div>
        </Col>
        <Col lg={4}>
        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\acura3.jpg" />
        </div>
        </Col>
      </Row>

      <Row className=' justify-content-center'>
        <Col lg={4}>

        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\acura4.jpg" />
        </div>
        </Col>
        <Col lg={4}>
        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\acura5.jpg" />
        </div>
        </Col>
        <Col lg={4}>
        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\acura2.jpg" />
        </div>
        </Col>
      </Row>
      
      <Row className='spacer justify-content-center'>
        <h1>Lexus IS 350 F-Sport</h1>
      </Row>

      <Row className='spacer justify-content-center'>
        <Col lg={4}>

        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\lexus1.jpg" />
        </div>
        </Col>
        <Col lg={4}>
        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\lexus2.jpg" />
        </div>
        </Col>
        <Col lg={4}>
        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\lexus3.jpg" />
        </div>
        </Col>
      </Row>

      <Row className=' justify-content-center'>
        <Col lg={4}>

        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\lexus4.jpg" />
        </div>
        </Col>
        <Col lg={4}>
        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\lexus5.jpg" />
        </div>
        </Col>
        <Col lg={4}>
        <div className="">
          <img className="img-thumbnail" src="\trikl\OurWork\lexus6.jpg" />
        </div>
        </Col>
      </Row>
<hr></hr>
      <Row className='justify-content-center'>

<h2>More clean cars to come</h2>
</Row>
<Row className='justify-content-center'>
<p>
  Stay tuned!!
</p>
</Row>
    </Container>

    <Container fluid className="otherSocials">
        <div className="socials-wrap">
        <Row className='justify-content-center'>
      <h2>
  Follow TRIKL:
</h2>
      </Row>
            <Row className="justify-content-center">
                <Col lg={2} className="instagram">
                <a id="instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/trikl.detail/">
                    <i className="grow fab fa-instagram"></i>
                </a>
                </Col>
                <Col lg={2} className="twitter">
                <a id="twitter" target="_blank" rel="noreferrer" href="https://www.twitter.com/RefillDranks/">
                    <i className="grow fab fa-twitter"></i>
                </a>
                </Col>

                <Col lg={2} className="tiktok">
                <a id="tiktok" target="_blank" rel="noreferrer" href="https://www.tiktok.com/@trikldetail">
                    <div class="tiktoklogo">
                        <i class="grow fab fa-tiktok"></i>
                    </div>
                </a>
                </Col>
            </Row>

         
        </div>
    </Container>

  </div>
</div>
);
}

export default TRIKL;