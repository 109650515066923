import FAQs from '../Components/TRIKL/TRIKL';


import {Helmet} from "react-helmet";

export default function TRIKL(){
    return(
        <div>
             <Helmet>
        <title>David Castaneda | TRIKL</title>
        <meta name="description" content="Nested component" />
    </Helmet>

             <FAQs/>
        </div>
    )

}