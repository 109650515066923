import './Footer.css';
import '../Main.css';
import { Row, Container, Col } from 'react-bootstrap';



function Footer () {
return (
<footer>
  <div id="contact" className="contact-info">
<Container fluid>
<div className="wrapper">


<h3 className='center spacer'>Let's keep in touch!!</h3>
  <Row>
  <Col lg={4}>
    <p><b>Socials</b></p>
    <Row>
        <a href="https://www.instagram.com/im.davidcastaneda/" rel="noreferrer" target="_blank"><i
            class="fab fa-instagram"></i> Instagram</a>
      </Row>
      <Row>
        <a href="https://www.tiktok.com/@trikldetail" rel="noreferrer" target="_blank"><i
            class="fab fa-tiktok"></i> Tik Tok</a>
      </Row>
      <Row>
        <a href="https://www.twitter.com/RefillDranks/" rel="noreferrer" target="_blank"><i class="fab fa-twitter"></i> Twitter</a>
      </Row>
      <Row>
        <a href="https://www.reddit.com" rel="noreferrer" target="_blank"><i class="fab fa-reddit"></i> Reddit</a>
      </Row>
      <Row>
        <a href="https://www.linkedin.com" rel="noreferrer" target="_blank"><i
            class="fab fa-linkedin"></i> LinkedIn</a>
      </Row>
      <Row>
        <a href="https://dec98524.github.io/" rel="noreferrer" target="_blank"><i class="fab fa-github-square"></i> Git
          Hub</a>
      </Row>
      </Col>

      <Col lg={4}>
      <p><b>Misc</b></p>
      <Row>
        <a href="/faq" rel="noreferrer"><i class="fa-solid fa-question"></i> FAQs</a>
      </Row>
      <Row>
        <a href="/blogs" rel="noreferrer"><i class="fa-solid fa-newspaper"></i> Blogs</a>
      </Row>
      <Row>
        <a href="https://cash.app/$davidcastanedacash" rel="noreferrer" target="_blank"><i class="fa-solid fa-hand-holding-dollar"></i> Donate</a>
      </Row>
      </Col>

      <Col lg={4}>
      <p><b>Contact</b></p>
        <Row>
        <a href="tel:310-748-3657"><i class="fas fa-phone-volume"></i> (310) 748-3657</a>
      </Row>
      <Row>
        <a href="mailto:contactdavidcastaneda@gmail.com">
          <i class="fas fa-envelope"></i> contactdavidcastaneda@gmail.com</a>
      </Row>
      </Col>
      </Row>
      </div>
      </Container>
      </div>

      {/* <p className='center'>Powered by <i class="fa-brands fa-react"></i> React and <i class="fa-brands fa-aws"></i> AWS</p> */}
      <Row className='justify-content-center'><img alt="halo david" className="img-fluid unselectable" src="/haloDavidW.png"></img>
      </Row>
      <p className='spacer center'>&copy; Copyright {new Date().getUTCFullYear()} David Castaneda</p>
       

</footer>

);
}

export default Footer;
