// import Socials from '../Components/Socials/Socials';
import {Helmet} from "react-helmet";
import MyResume from '../Components/MyResume/MyResume';


export default function Resume(){
return(
<div>
    <Helmet>
        <title>David Castaneda | Resume</title>
        <meta name="description" content="Nested component" />
    </Helmet>

    
    <MyResume />

</div>
)

}