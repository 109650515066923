import './Contact.css';
import '../Main.css';
import { Row, Col, Container } from 'react-bootstrap';
import ContactUs from '../contactForm';

function Contact () {
return (
<div>


<Container fluid className=" wrapper">
    <Row className="spacer">
      {/*
      <Col lg={3}>
      <p className='bigIcon'><i class="fa-solid fa-house-chimney"></i></p>
      </Col> */}
      <Col lg={12}>
      <h1 className='myH1'>Contact</h1>
    

      </Col>
    </Row>




    <Row className="">
      <Col lg={3}>
      <Row className="spacer justify-content-center">
      <h3 className='bigIcon'><i class="fa-solid fa-circle-question"></i></h3>
      
</Row>

      </Col>
      <Col lg={9}>
      <h1 className='myH1'>Questions?</h1>
      <p>
      Thanks for visiting my website. If you are on this page, odds are you want to get in touch with me. There are
        many ways to reach me. If you are looking to either meet up with me or send me something, my address is listed
        below. Another way you can reach me is by sending me an email. This can be helpful when sending media such as photos,
        documents or audio files. This is the most effective way to contact me. My email address is listed below. Want to talk? My phone number is listed below. I will try my best to respond to either your call or text message as soon as possible. Maybe we can even play GamePigeon games :p

      </p>

      </Col>
    </Row>




<Row className='spacer justify-content-center'>
      <ul class="contactList ">
        <li><i class="fas fa-map-marked-alt"></i> 8816 Canby Ave, Northridge, CA 91325</li>
        <li><i class="fas fa-phone-volume"></i> (310) 748-3657</li>
        <li><i class="fas fa-envelope"></i> contactdavidcastaneda@gmail.com</li>
      </ul>    
      </Row>

  </Container>

  <Container fluid className="greyBox">
   
    <div className="wrapper">

    <h2 className="">
        Send me a message below:
      </h2>
      <ContactUs/>
    

    </div>
    
  </Container>
</div>
);
}

export default Contact;