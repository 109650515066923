import Hero from '../Components/Hero/Hero';
import About from '../Components/About/About';
// import Timeline from '../Components/Timeline/Timeline';
// import Socials from '../Components/Socials/Socials';

import {Helmet} from "react-helmet";

export default function Home(){
return(
<div>
    <Helmet>
        <title>David Castaneda | Home</title>
        <meta name="description" content="Nested component" />
    </Helmet>
    <Hero/>
    <About /> 
</div>
)

}