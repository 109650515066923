import Blog0 from '../../Components/Blogs/Blog0';
import {Helmet} from "react-helmet";


export default function Blog0Page(){
    return(
        <div>
            <Helmet>
        <title>David Castaneda | Blog 0</title>
        <meta name="description" content="Nested component" />
    </Helmet>
            <Blog0/>
        </div>
    )

}