import './Hero.css';
import '../Main.css';
import LazyHero  from 'react-lazy-hero';
import 'animate.css';




function Hero () {
return (
<div>
<LazyHero
      
          parallaxOffset="20"
          // isFixed="false"
          // isCentered="false"
          transitionTimingFunctio="ease-in-out"
          minHeight="70vh" 
          color="black"
          opacity="0.6"
          imageSrc="/hero1.jpg">
                <h3 className="animate__animated animate__zoomInDown whiteh3 heroFont">DAVID CASTANEDA</h3>
                {/* <h4 className="whiteh4"><i class="fas fa-laptop-code"></i>  <i class="fas fa-baseball-ball"></i>  <i class="fas fa-suitcase"></i></h4> */}
                
                <p className="animate__animated animate__zoomInDown power">Powered by <i class="fab fa-react"></i></p>
                

                </LazyHero>
</div>
);
}

export default Hero;