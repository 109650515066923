import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog22 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #22</h1>
                <p>By: David Castaneda</p>
                <p>Date: 4/30/2021</p>


                <h1>
                Introduction - AWS Route 53
            </h1>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/route531.png" alt="Your Name" />

                </Row>
                <p>
One thing that you will definitely need across your web development career is a domanin name. A domain name is simply the name of your website. WIth domain names in place, we don't have to worry about typing a long and rather forgettable numeric IP address. A domain name simply maps a string of characters to a numeric IP so that you can search for websites with ease. For example, instead of typing the IP 123.236.234.234 which canget congusing, you can simply type something like example.com. There are many ways to go about doing this, including the srvice known as Route 53 from AWS.

</p>


<p>
  To start off, let's get an understanding of what AWS Route 53 is       
</p>
<h2>
    From Amazon Web Services: 
</h2>
<blockquote>
Amazon Route 53 is a highly available and scalable cloud Domain Name System (DNS) web service. It is designed to give developers and businesses an extremely reliable and cost effective way to route end users to Internet applications by translating names like www.example.com into the numeric IP addresses like 192.0.2.1 that computers use to connect to each other. Amazon Route 53 is fully compliant with IPv6 as well.

Amazon Route 53 effectively connects user requests to infrastructure running in AWS – such as Amazon EC2 instances, Elastic Load Balancing load balancers, or Amazon S3 buckets – and can also be used to route users to infrastructure outside of AWS. You can use Amazon Route 53 to configure DNS health checks to route traffic to healthy endpoints or to independently monitor the health of your application and its endpoints. Amazon Route 53 Traffic Flow makes it easy for you to manage traffic globally through a variety of routing types, including Latency Based Routing, Geo DNS, Geoproximity, and Weighted Round Robin—all of which can be combined with DNS Failover in order to enable a variety of low-latency, fault-tolerant architectures. Using Amazon Route 53 Traffic Flow’s simple visual editor, you can easily manage how your end-users are routed to your application’s endpoints—whether in a single AWS region or distributed around the globe. Amazon Route 53 also offers Domain Name Registration – you can purchase and manage domain names such as example.com and Amazon Route 53 will automatically configure DNS settings for your domains.
</blockquote>

<p>
In a nutshell, Route 53 is a service that allows you to manage all things regarding your domain. YOu can purchase new ones or manage existing ones. You have the ability to create and customize hosted zones all in the comfort of the Route 53 Dashboard. Route 53 is able to be seamlessly integrated with a number of AWS Services as well.      
</p>

<h1>
Getting Started With AWS Route 53
</h1>


<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/route532.png" alt="Your Name" />

                </Row>

<p>To get strated you will have to purchase a domain. You have a wide variety of options to choose from, and the price will vary depending on the domain. The domain I chose was "advancedstadia.com" and it was $12.00. You will have to fill out some information about yourself which is required when purchasing a domain. You have the option to keep your information private, so be sure to check that option. Once complete, AWS will send you an approval email confirming the domain is yours</p>

<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/route533.png" alt="Your Name" />

                </Row>


<p>
Now you have the ability to customize and configure your hosted zone. What this means is you can begin to map your domain name with certain projects. For example, I am linking my domain nam to my AWS Amplify app so i can search it via the domain as opposed to the auto generated Amplify link. To do this, click on hosted zones and create a new record. For the name, it is a good idea to write in an asterik to cover any prefixes your url might have, such as www. and api. keep most settings default, but check the alias value on. From there you should be able to link your doamin to any AWS projects.</p> 
<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/route534.png" alt="Your Name" />

                </Row>

<h2>Final Thoughts</h2>
<p>
    There are many other ways you can go about managing and acquiring domains, such as GoDaddy. While other options are solid, I feel like AWS Route 53 is great due to the seamless integration with other AWS services such as AWS EC2, AWS Elastic Beanstalk and AWS Amplify. I was able to simply and easily connect a domain to my personal website. This website is currently being hosted on AWS with a Route 53 domain. As you can see, typing in and remembering <a href="https://www.advancedstadia.com">https://www.advancedstadia.com</a> is much easier than remembering and typing out the default URL of <a href="https://main.dnwuu0zbwiv4v.amplifyapp.com">https://main.dnwuu0zbwiv4v.amplifyapp.com</a> 
</p>
        </div>
    </Container>
</div>
);
}

export default Blog22;
