import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog20 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #20</h1>
                <p>By: David Castaneda</p>
                <p>Date: 4/9/2021</p>


                <h1>
                Introduction - AWS Amplify
            </h1>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/amplify1.png" alt="Your Name" />

                </Row>
                <p>
There are many ways to host a static site on the internet, such as using GitHub pages and Glitch. While these avenues are solid, one of the better and most effective and efficient ways to host a static site is by using Amazon Web Services. AWS offers a service dedicated to providing the tools necessary when it comes to hosting your very own static site known as AWS Amplify. In this blog, I will go over what AWS Amplify is, how to use the service and what you can do with the tools available at your disposal  

</p>


<p>
  To start off, let's get an understanding of what AWS Amplify is       
</p>
<h2>
    From Amazon Web Services: 
</h2>
<blockquote>
AWS Amplify is a set of tools and services that can be used together or on their own, to help front-end web and mobile developers build scalable full stack applications, powered by AWS. With Amplify, you can configure app backends and connect your app in minutes, deploy static web apps in a few clicks, and easily manage app content outside the AWS console.
Amplify supports popular web frameworks including JavaScript, React, Angular, Vue, Next.js, and mobile platforms including Android, iOS, React Native, Ionic, Flutter. Get to market faster with AWS Amplify.
</blockquote>

<p>
In a nutshell, AWS Amplify is a way to host your static site on the AWS cloud. With Amplify, you are free to use a variety of frameworks such as React, Vue and Angular. In addition, Amplify makes it easy to develop and host apps on mobile platforms such as iOS. This can all be done from the comfort of the AWS Management Console and can be implemnented with a number of AWS features.     
</p>

<h1>
Getting Started With AWS Amplify
</h1>

<p>Now that we have a decent understanding of how AWS Amplify operates and what it has to offer, let's get started with a project. Once you are ready, head over to to the AWS management console and login. From the list of services, locate and navigate over to "AWS Amplify". Once there, click on the Get Started tab. For this blog I will be developing an app. </p>

<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/amplify2.png" alt="Your Name" />

                </Row>

<p>You will start off by giving your application a name. This can be anything you want, just remember this will be your reference to your application. Once you decided on a name, select "Confirm Deployment". From here, you will be able to deploy a frontend environment in many ways. You have the choice of using a GitHub/GitLab repository., AWS CodeCommit or Bit Bucket. For this example, I will be using a GitHub repository. If you haven't already, link your GitHub account to AWS account in order to access all your repositories. Once linked, simply select the GitHub repository you want as your frontend source.</p>

<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/amplify3.png" alt="Your Name" />

                </Row>


<p>
Once you have selected your desired repository, save and deploy your project. It will take some time to provision and fully deploy, so be a bit patient. If all goes as planned, your app should be fully deployed and accessible via the AWS cloud!</p> 
<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/amplify4.png" alt="Your Name" />

                </Row>
<p>
    Keep in mind that since your AWS is linked to your GitHub, any updates you make on your repository will immediately be reflected on your app once successfully committed and pushed. This eliminates the need to constantly need updaste your application from the Amplify dashboard, it will all be done automatically!
</p>
<h2>Final Thoughts</h2>
<p>
    Once deployed, AWS Amplify apps are a pleasure to manage. Everything is kept and contained in a dashboard that will contain everything you need to provision and manage your project as you please. An example of a feature present in AWS Amplify is domain management. If you have a custom domain you have that you would like to associate with your Amplify app, you are able to do so. Amplify can work in conjunction with Route 53 to make the process of managing a domain that much easier and less of a headache. Other features available to you with amplify include admin UI management, build settings, environmental variables, access control, monitoring and custom headers. 
</p>
        </div>
    </Container>
</div>
);
}

export default Blog20;
