import './FAQs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion'
import ContactUs from '../contactForm';
import LazyHero from 'react-lazy-hero';


function FAQs () {
return (
<div>
  <div className="greyBox">

  <LazyHero parallaxOffset="20" // isFixed="false" // isCentered="false" transitionTimingFunctio="ease-in-out"
    minHeight="70vh"  color="black" opacity="0.6" imageSrc="/faq.png">
    <h3 className="whiteh3 heroFont">Frequently Asked Questions</h3>
  </LazyHero>


    <Container className='spacer'>
    <h1 className='myH1'>FAQs</h1>

    <Accordion className='spacer'>

  <Accordion.Item eventKey="0">
    <Accordion.Header className="faqQuestion">What does "Halo David" mean? <i class="fa-solid fa-chevron-down"></i></Accordion.Header>
    <Accordion.Body className="spacer">
        <blockquote>
      One day, I was trying to build a name for my personal brand and I ultimately landed on "Halo David". My name is David Castaneda and I am a huge fan of the Los Angeles Angels baseball team. I have rooted for the Angels all my life and always considered them part of my identity. One of the nickname of the Angels is "The Halos", which is pretty self explanatory. In the end, I thought it was a good idea to combine my love for the Halos with my name!
      </blockquote>
    </Accordion.Body>
  </Accordion.Item>




  <Accordion.Item eventKey="1">
    <Accordion.Header className="faqQuestion">How old are you? <i class="fa-solid fa-chevron-down"></i></Accordion.Header>
    <Accordion.Body className="spacer">
        <blockquote>
      My birthday is May 11th, 1999 and I am 25 years old!  
      </blockquote>
    </Accordion.Body>
  </Accordion.Item>





  <Accordion.Item eventKey="2">
    <Accordion.Header className="faqQuestion">Can you help me build a website? <i class="fa-solid fa-chevron-down"></i></Accordion.Header>
    <Accordion.Body className="spacer">
        <blockquote>
      Yes! I am looking to improve my skills and would be thrilled to help you with a website!Feel free to contact me to discuss rates and designs, and to see if we can make this vision a reality!
      </blockquote>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="3">
    <Accordion.Header className="faqQuestion">Why should I be interested in your work? <i class="fa-solid fa-chevron-down"></i></Accordion.Header>
    <Accordion.Body className="spacer">
        <blockquote>
      As a developer, I am looking to grow every chance I get. The opportunity to work on a project gives me the chance to learn new things on the job and refine my skills. I am passionate about web development, and am looking for every and any chance I get to improve. While I don't have many projects under my belt, I hope to showcase my potential in the future with more opportunities.  
      </blockquote>
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="4">
    <Accordion.Header className="faqQuestion">What should a potential employer know about you? <i class="fa-solid fa-chevron-down"></i></Accordion.Header>
    <Accordion.Body className="spacer">
        <blockquote>
      There are a few key things I want potential employers to know about me. The first thing is that I am very passionate about growing as a developer. I know for a fact that web development is what I want to do for a living. While I lack the experience, I am a strong believer in becoming the best I possibly can. Another thing is that I work well with others and encourage collaboration. I acknowledge that I am far from perfect, and working with others gives me the chance to learn and pick up new things from others. The last thing is that I am reliable. I will always make my job my priority and always strive to be as dependable as possible.      
      </blockquote>
    </Accordion.Body>
  </Accordion.Item>




  {/* CHANGE EVENT KEY INCREMENTALLY PER ACCORIDON ITEM */}

</Accordion>

<Row className='justify-content-center'>
  <h1>Still have a question for me?</h1>
</Row>
<Row className='justify-content-center'>
<p>Shoot me an email!</p>
</Row>
<ContactUs />
    </Container>
  </div>
</div>
);
}

export default FAQs;