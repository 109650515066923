import Blog7 from '../../Components/Blogs/Blog7';
import {Helmet} from "react-helmet";


export default function Blog7Page(){
    return(
        <div>
                        <Helmet>
        <title>David Castaneda | Blog 7</title>
        <meta name="description" content="Nested component" />
    </Helmet>

            <Blog7/>
        </div>
    )

}

