import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog12 () {
return (
<div>

  <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
  <Container fluid>
    <div className="blog-wrapper">
      < h1 className="spacer">Blog #12</h1>
        <p>By: David Castaneda</p>
        <p>Date: 12/4/2020</p>


        <h1>
          Introduction - Google Maps API
        </h1>
        <Row className="spacer justify-content-center">
          <img className="img-fluid" src="blogimages/misc/google-maps-api.jpg" alt="Your Name" />

        </Row>
        <p>
          For this blog, I will be talking about the Google Maps API. For various reasons, whether it be to inform users
          on how to get to your business or to figure out the fastest way to get from point A to point B, most modern
          websites include some sort of map. Implementing a map from scratch manually can be quite a headache and in
          many cases inaccurate and unreliable . Luckily, The Google Maps API is aeasy, fast and great way to include
          interactive and responsive maps to your website or application. There are pre-built many tools to that will
          assist you when designing your map. In this blog, I will cover what the Google Maps API is, some cool things
          you can do with it, and how to implement it into your project.
        </p>

        <h1>
          What is the Google Maps API?
        </h1>

        <p>
          If you have ever ventured over to a website and seen an integrated map widget, you probably have wondered just
          how the web developer was able to implement it. I mean, it's not like there is a simple HTML element that
          allows us to just spawn a map. Well, odds are that they used the Google Maps API. Luckily for us as
          programmers, using the Google Maps API is not as hard as it may seem.</p>
        <h2>
          From Google:
        </h2>
        <blockquote>
          The Maps JavaScript API lets you customize maps with your own content and imagery for display on web pages and
          mobile devices. The Maps JavaScript API features four basic map types (roadmap, satellite, hybrid, and
          terrain) which you can modify using layers and styles, controls and events, and various services and
          libraries.
        </blockquote>
        <p>
          There are many ways we as developers can go about using the Google Maps API. There are different collections
          for different softwares. For example, there is the Maps SDK for Android which allows you to add a map to your
          Android app, the Maps SDK for IOS which allows you to add a map to your IOS app and the Maps JavaScript API,
          (the one we will be using for this blog)which allows you to add an interactive map to your website. Customize
          it with your own content and imagery

        </p>

        <h2>
          Accessing the Google Maps JavaScript API </h2>
        <p>
          There are a few steps that need to be taken in order to access the Google Maps JavaScript API. For starters,
          we will have to register for Google Cloud Platform and set up an acocunt. To do this, head over to <a
            href="https://cloud.google.com/maps-platform/#get-started">https://cloud.google.com/maps-platform/#get-started</a>
          using your Google Account </p>


        <Row className="spacer justify-content-center">
          <img className="img-fluid" src="blogimages/misc/maps-2.png" alt="Your Name" />

        </Row>

        <p>
          Once you have an account, the next step will be to create a billing account. Unfortunately, this is mandatory.
          Google is adamant that they will not charge your card unless you manually change it to do just that, so let's
          hope they are telling the truth. Once you have set up your billing information, the next step is to create a
          project within Google Cloud Platform. To do this, simply navigate over to the top of the page on the blue
          navigation bar. You will see a option to create a new project. Click this and follow the steps. Once you have
          set up your new project, hit the sidebar on the left and go to APIs & Services &gt; Dashboard &gt; Enable APIs
          and services. Select and enable the JavaScript API.
        </p>


        <Row className="spacer justify-content-center">
          <img className="img-fluid" src="blogimages/misc/maps-3.png" alt="Your Name" />

        </Row>

        <p>
          Once you have done that, the last step is to head over to APIs & Services &gt; Credentials &gt; Create
          Credentials &gt; API Key. Once comppleted, you should have a generated API Key that will give you access to
          the Google Maps API.
        </p>




        <h2>
          Including a Simple Map In Your Project
        </h2>

        <p>
          The next thing we will do is include this in a webb app. To start we will write a very basic HTML document. In
          this document, we will include the necessary tags that will unlock our dependencies. Implement the following
          into your document:
        </p>
        <pre>
             &lt;html&gt;<br></br>
             &lt;head&gt;<br></br>
               &lt;title&gt;Simple Map&lt;/title&gt;<br></br>
               &lt;script src=&quot;https://polyfill.io/v3/polyfill.min.js?features=default&quot;&gt;&lt;/script&gt;<br></br>
               &lt;script<br></br>
                 src=&quot;https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&amp;callback=initMap&amp;libraries=&amp;v=weekly&quot;
                 defer
               &gt;&lt;/script&gt;<br></br>
               &lt;link rel=&quot;stylesheet&quot; type=&quot;text/css&quot; href=&quot;./style.css&quot; /&gt;<br></br>
               &lt;script src=&quot;./index.js&quot;&gt;&lt;/script&gt;<br></br>
             &lt;/head&gt;<br></br>
             &lt;body&gt;<br></br>
               &lt;div id=&quot;map&quot;&gt;&lt;/div&gt;<br></br>
             &lt;/body&gt;<br></br>
           &lt;/html&gt;
             </pre>
        <p>
          This is the very minimum we need, regarding HTML code, in order to include a Google Map into our project.
          <br></br>
          <b>NOTE:</b> You need to change the script tag to include YOUR API KEY from the previous step. Otherwise, your
          map will not load!<br></br>
          Next is the CSS file, implement the following:
        </p>

        <pre>
               /* Always set the map height explicitly to define the size of the div
                  * element that contains the map. */<br></br>
           #map &#123;<br></br>
             height: 100%;<br></br>
             &#125;<br></br>
             <br></br>
           /* Optional: Makes the sample page fill the window. */<br></br>
           html,
           body &#123;<br></br>
             height: 100%;<br></br>
             margin: 0;<br></br>
             padding: 0;<br></br>
             &#125;<br></br>
             </pre>
        <p>
          The #map selector is used to alter the map widget in our HTML file. We must inclde the height and set it to a
          value in order to make the map appear.<br></br>
          Next is the JavaScript file, include the following:
        </p>

        <pre>
               let map;<br></br>
           
           function initMap() &#123;<br></br>
             map = new google.maps.Map(document.getElementById("map"), &#123;<br></br>
               center: &#123; lat: -34.397, lng: 150.644 &#125;,<br></br>
               zoom: 8,<br></br>
               &#125;);<br></br>
             &#125;
             </pre>

        <p>
          This is the actual code that reaches into the Google Maps API and initializes our map. The function initMap()
          is pre-built along with all of it's properties. With all of this we now have included a Google Map in our
          site!!
        </p>

        <h2>
          Included Tools
        </h2>
        <p>
          There are a ton of customization options available for you as a developer within the Maps API. Here are some
          examples:
        </p>
        <ul>
          <li>Custom Markers and Pins</li>
          <li>Custom Map Themes</li>
          <li>Shape Tools</li>
          <li>Disable/Enable UI</li>
          <li>Tooltips</li>
          <li>Heat Maps</li>
        </ul>
        <p>
          * Full documentation <a
            href="https://developers.google.com/maps/documentation">HERE</a>
        </p>



        <h1>
          Final Thoughts
        </h1>
        <p>
          Being able to include a fully functioning map into a website can be very helpful. I am happy to have learned
          how to use the Google Maps API because Google Maps is arguably the best map service in the world. The
          possibilites of what can be devloped are endless. For my starter project, I used the Google Maps API to make a
          game where the user guesses where certain buildings are at the CSUN Campus. <a
            href="https://david-maps-project.glitch.me/">Here is a link</a> to the project. I was able to create many
          different features with all the pre built tools such as drawing rectangles via coordinates, adding event
          listeners, etc. I am eager to learn more APIs in the future!
        </p>
    </div>
  </Container>
</div>
);
}

export default Blog12;