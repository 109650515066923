import './Timeline.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement}
from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import CakeIcon from '@mui/icons-material/Cake';
import SchoolIcon from '@mui/icons-material/School';
import LuggageIcon from '@mui/icons-material/Luggage';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PendingIcon from '@mui/icons-material/Pending';

function Timeline () {
return (
<div>

<Container fluid className="blackBox">
<div class="custom-shape-divider-top-1645407743">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div>
    <div className="wrapper">
    <Row className="spacer justify-content-center">
        <h1>My Journey</h1>
      </Row>
<VerticalTimeline>
<VerticalTimelineElement
    className="vertical-timeline-element--star"
    contentStyle={{ background: '#00b5ff', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="May 1999"
    iconStyle={{ background: '#00b5ff', color: '#fff' }}
    icon={<CakeIcon />}
  >
    <h3 className="vertical-timeline-element-title">I WAS BORN</h3>
    <h4 className="vertical-timeline-element-subtitle">Los Angeles, California</h4>
    <p>
    The journey all begins with my beautiful mother giving birth to me on May 11th, 1999 in Los Angeles, California. 
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: '#06315c', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="June 2017"
    iconStyle={{ background: '#06315c', color: '#fff' }}
    icon={<SchoolIcon />}
  >
    <h3 className="vertical-timeline-element-title">High School Graduation</h3>
    <h4 className="vertical-timeline-element-subtitle">Culver City, California</h4>
    <p>
      After four stress inducing years, in 2017 my high school journey came to an end as I graduated from Culver City High School. Next stop: California State University: Northridge.
    </p>
  </VerticalTimelineElement>
  
  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: '#b31b1b', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="March 2019"
    iconStyle={{ background: '#b31b1b', color: '#fff' }}
    icon={<LuggageIcon />}
  >
    <h3 className="vertical-timeline-element-title">Leaving Home</h3>
    <h4 className="vertical-timeline-element-subtitle">Culver City &#187; CSUN Dorms</h4>
    <p>
      After commuting three hours to school for two years, it was time for me to leave home and start living on my own...kinda. Moving into on-campus dorms was a big first step in becoming independent.    
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: '#df661b', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="June 2019"
    iconStyle={{ background: '#df661b', color: '#fff' }}
    icon={<HomeIcon />}
  >
    <h3 className="vertical-timeline-element-title">Moving Out</h3>
    <h4 className="vertical-timeline-element-subtitle">Northridge, California</h4>
    <p>
      After saving up enough money, I was able to move out and rent a room in the Valley. Although I missed my hometown, I was stoked to be living on my own. 
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: '#008938', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="August 2019"
    iconStyle={{ background: '#008938', color: '#fff' }}
    icon={<RestaurantIcon />}
  >
    <h3 className="vertical-timeline-element-title">My First Job</h3>
    <h4 className="vertical-timeline-element-subtitle">CSUN Subway</h4>
    <p>
      Although a little later than most, my first job came at the age of 20 at the Subway in my college campus. While a suprisingly fun first job, my tenure as a sandwich artist was cut short as a result of COVID. 
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: '#7a7a7a', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="April 2021"
    iconStyle={{ background: '#7a7a7a', color: '#fff' }}
    icon={<DirectionsCarIcon />}
  >
    <h3 className="vertical-timeline-element-title">My First Car</h3>
    <h4 className="vertical-timeline-element-subtitle">2014 Honda Civic</h4>
    <p>
      My first car was a 2014 Honda Civic LX Coupe. A fantastic car that I purchased completely solo, in full, and one I am proud to drive.    
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: '#b31b1b', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="May 2021"
    iconStyle={{ background: '#b31b1b', color: '#fff' }}
    icon={<SchoolIcon />}
  >
    <h3 className="vertical-timeline-element-title">College Graduation</h3>
    <h4 className="vertical-timeline-element-subtitle">CSUN</h4>
    <p>
      After <i>another</i> stressful four years, I earned my Bachelor's degree in Computer Information Technology.   
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: '#95c851', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="June 2021"
    iconStyle={{ background: '#95c851', color: '#fff' }}
    icon={<WarehouseIcon />}
  >
    <h3 className="vertical-timeline-element-title">Inventory Agent</h3>
    <h4 className="vertical-timeline-element-subtitle">Curacao</h4>
    <p>
      Upon graduating college, I began working for Curacao as an Inventory Control Agent for the entire store, and continue to work there to this day.   
    </p>
  </VerticalTimelineElement>
  
  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: '#b31b1b', border:'white solid 2px', color: '#fff'  }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="February 2023"
    iconStyle={{ background: '#b31b1b', color: '#fff' }}
    icon={<DirectionsCarIcon />}
  >
    <h3 className="vertical-timeline-element-title">My 2nd Car</h3>
    <h4 className="vertical-timeline-element-subtitle">2023 Acura Integra A-Spec</h4>
    <p>
      After roughly two years with my Civic, I decided it was time for an upgrade. I traded in my Civic and upgraded to a 2023 Acura Integra in Performance Red Pearl. While parting ways with my first car was hard, I am loving my new car!    
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--star"
    contentStyle={{ background: '#EF60A3', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="March 2024"
    iconStyle={{ background: '#EF60A3', color: '#fff' }}
    icon={<FlightTakeoffIcon />}
  >
    <h3 className="vertical-timeline-element-title">My First Flight</h3>
    <h4 className="vertical-timeline-element-subtitle">Oahu, Hawaii</h4>
    <p>
    After 24 years of living, 2024 was the year I finally had the opportunity to go on my first flight. I had the privilege of visiting the beautiful island of Oahu in Hawaii. The trip was an experience I will cherish forever.      
    </p>
  </VerticalTimelineElement>

  {/* <VerticalTimelineElement
    className="vertical-timeline-element--star"
    contentStyle={{ background: '#00b5ff', border:'white solid 2px', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date="June 2023"
    iconStyle={{ background: '#00b5ff', color: '#fff' }}
    icon={<WaterDropOutlinedIcon />}
  >
    <h3 className="vertical-timeline-element-title">TRIKL</h3>
    <h4 className="vertical-timeline-element-subtitle">Auto Detailing</h4>
    <p>
    After getting a brand new car, I began appreciating the process of taking care of vehicles. As a result, I picked up the hobby of auto detailing. While still learning, I hope to one day start a business of auto detailing.     
    </p>
  </VerticalTimelineElement>
 */}


  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<PendingIcon />}
  />

</VerticalTimeline>


</div>
<div class="custom-shape-divider-bottom-1645408672">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div>
</Container>



</div>
);
}

export default Timeline;