import './Blogs.css';
import '../Main.css';
import { Row, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";


function Blog21 () {
return (
<div>

    <Link class="goHome" id="myBtn" to="/blogs"><i class="fas fa-home"></i></Link>
    <Container fluid>
        <div className="blog-wrapper">
            < h1 className="spacer">Blog #21</h1>
                <p>By: David Castaneda</p>
                <p>Date: 4/30/2021</p>


                <h1>
                Introduction - AWS Elastic Beanstalk
            </h1>
                <Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/beanstalk1.png" alt="Your Name" />

                </Row>
                <p>
In previous blogs, I have gone over various ways to host a static website. One of the main services I covered was AWS Amplify, which is a service offered on Amazon Web Services which allows you to host your very own static site. This service is great for static sites, but what if you wanted to elevate your web app? Luckily, Amazon Web Services allows users with dynamic web apps to easily upload and deploy a project to the AWS Cloud. This service is known as Elastic Beanstalk.

</p>


<p>
  To start off, let's get an understanding of what AWS Elastic Beanstalk is       
</p>
<h2>
    From Amazon Web Services: 
</h2>
<blockquote>
AWS Elastic Beanstalk is an easy-to-use service for deploying and scaling web applications and services developed with Java, .NET, PHP, Node.js, Python, Ruby, Go, and Docker on familiar servers such as Apache, Nginx, Passenger, and IIS. You can simply upload your code and Elastic Beanstalk automatically handles the deployment, from capacity provisioning, load balancing, auto-scaling to application health monitoring. At the same time, you retain full control over the AWS resources powering your application and can access the underlying resources at any time. There is no additional charge for Elastic Beanstalk - you pay only for the AWS resources needed to store and run your applications.
</blockquote>

<p>
In a nutshell, AWS Elastic Beanstalk is a way to upload, deploy and host your web based application on the AWS cloud. With Elastic Beanstalk, you are free to use a variety of platformds such as Java, .NET, PHP, Node.js, Python, Ruby, Go, and Docker. In addition, Elastic Beasnstalk gives you the freedom and flexability to configure your environment to meet the needs of your project. This can all be done from the comfort of the AWS Management Console and can be implemnented with a number of AWS features.     
</p>

<h1>
Getting Started With AWS Elastic Beanstalk
</h1>

<p>Now that we have a decent understanding of how AWS Elastic Beanstalk operates and what it has to offer, let's get started with a project. Once you are ready, head over to to the AWS management console and login. From the list of services, locate and navigate over to "AWS Elastic Beanstalk". Once there, click on the Create Application tab. For this blog I will be taking a node app and uploading, deploying it and hosting it via Elastic Beanstalk.</p>

<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/beanstalk2.png" alt="Your Name" />

                </Row>

<p>You will start off by giving your application a name. This can be anything you want, just remember this will be your reference to your application. Once you decided on a name, you are going to want to pick a platform and a version for your application to run on. For this example, I will be using Node.js with its default settings. The last thing you are going to want to do is add a source code, this being your application. You have a few options, you can either upload your own code, or use a sample code provided by AWS. For this example, we will use the sample code.</p>

<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/beanstalk3.png" alt="Your Name" />

                </Row>


<p>
Once all your specs are configured hit create application. This will begin the process of creating the enviornment for your application. Essentially what is going on here is Elastic Beanstalk is creating an EC2 instance based on your configurations. In addition, an S3 bucket is being created and this is where your project's source code is being stored. The S3 data is read and executed inside of the newly created instance. After a few minutes, your environment should have been successfully created.</p> 
<Row className="spacer justify-content-center">
                    <img className="img-fluid" src="blogimages/misc/beanstalk4.png" alt="Your Name" />

                </Row>
<p>
    Now you can navigate over to the automatically generated elastic beanstalk url to view your web app in action. Another thing to keep in mind is that while AWS Elastic Beanstalk saves money overall, it is not 100% free. You will be charged for the resources you use such as the EC2 instance and S3 bucket. When you are finished and wish to delete it, be sure to delete everything to avoid being charged. Finally, keep in mind that Elastic Beanstalk is region specific!
</p>
<h2>Final Thoughts</h2>
<p>
    Once deployed, AWS Elastic Beanstalk apps allow you to do so many things in terms of configurations. Everything is kept and contained in a dashboard that will contain everything you need to provision and manage your project as you please. The options you have available include Capacity, Load Balancing,  Security and Monitoring. Beanstalk is a great way to seamlessly upload, deploy and host your dynamic web application for others to use around the world. In the future, I will cover how to go about uploading your own source code as opposed to the sample code as well as making the site secure via HTTPS and SSL certificates. 
</p>
        </div>
    </Container>
</div>
);
}

export default Blog21;
