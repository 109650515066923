import emailjs from 'emailjs-com'
import {Form, Button} from 'react-bootstrap'
import alertify from 'alertifyjs'

// process.env.REACT_APP_EMAILJS_USER_ID
export default function ContactUs(){
function sendEmail(e){
e.preventDefault();

emailjs.sendForm('service_hlf838j', 'template_58g5fm2', e.target, process.env.REACT_APP_EMAILJS_USER_ID)
.then((result) => {
console.log(result.text);


alertify.confirm("Your email was sent! I will try to get back to you as soon as possible.").set('oncancel', function(closeEvent){ window.location.href = "/";}).set("title", "Thank you for reaching out!").set('labels', {ok:'Stay', cancel:'Homepage'})

}, (error) => {
console.log(error.text);


alertify.alert('Error: Your message was not sent, please make sure everything is entered correctly and try again.').set("title", "Oh No!").set('label', 'Try Again'); 

});
e.target.reset();
}



return (
<div>

  <Form className="spacer contact-form" onSubmit={sendEmail}>
    <Form.Group>
      <Form.Label>Your Name</Form.Label>
      <Form.Control type="text" name="name" placeholder="Name" required />
    </Form.Group>

    <Form.Group>
      <Form.Label>Your Email</Form.Label>
      <Form.Control type="email" name="user_email" placeholder="Email" required />
    </Form.Group>

    <Form.Group>
      <Form.Label>Subject</Form.Label>
      <Form.Control type="text" name="subject" placeholder="Subject" required />
    </Form.Group>



    <Form.Group>
      <Form.Label>Your Message</Form.Label>
      <Form.Control as="textarea" rows={10} name="message" placeholder="What's on your mind??" required/>
    </Form.Group>

<div className="spacer"></div>
    <Button variant="primary" type="submit" value="Send">
      Submit
    </Button>

  </Form>
</div>

);
}

