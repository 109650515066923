import Blog1 from '../../Components/Blogs/Blog1';
import {Helmet} from "react-helmet";


export default function Blog1Page(){
    return(
        <div>
            <Helmet>
        <title>David Castaneda | Blog 1</title>
        <meta name="description" content="Nested component" />
    </Helmet>
            <Blog1/>
        </div>
    )

}