import './About.css';
import '../Main.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Row, Col, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";
import 'animate.css';




function About () {
return (
<div>


  <Container fluid className="greyBox wrapper">
    <Row className="spacer">
      {/*
      <Col lg={3}>
      <p className='bigIcon'><i class="fa-solid fa-house-chimney"></i></p>
      </Col> */}
      <Col lg={12}>
      <h1 id="home" className='myH1'>WELCOME <i
          class="animate__animated animate__heartBeat animate__delay-1s fa-regular fa-heart"></i></h1>
      <p>
        Thanks for stopping by...
      </p>

      </Col>
    </Row>


    <Row className='justify-content-center'><img alt="Halo David Wordmark" className="img-fluid unselectable animate__animated animate__pulse animate__delay-2s fa-regular fa-heart" src="/haloDavid.png"></img>
      </Row>

  
    <Row className="spacer">
      <Col lg={3}>
      <div className="">
          <img className="img-thumbnail bioPic"
            src="/david3.png"
            alt="David Castaneda wearing a duff beanie" />
        </div>
      </Col>
      <Col lg={9}>
      <h1 className='quoteIcon'> <i class="fas fa-quote-right"></i> From David:</h1>
      <p className='textBox'>
      As an aspiring web developer, nothing makes me happier than others visiting my website. To me, this website is a canvas where I can demonstrate everything I have learned while learning web development. While still an amateur, I hope to eventually do this professionally and for a living. Everyday, I work on little things with the goal of picking up new skills that will make me a stronger developer. I work hard to make this website as professional and appealing as possible, so I hope you enjoy your stay! ♡
      </p>

      </Col>
    </Row>


  </Container>

      {/*FLIPCARDS*/}
<Container fluid className="spacer greyBox">
    <div className="wrapper">
 
    <Row className=" justify-content-center">
      <h3 className='spacer'>Where to?</h3>
    </Row>


      <Row className="justify-content-around">
        <Col lg={3}>
        <Link to="/resume">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="redCardFront">
            <h3 className='flipCardIcon'><i class="fa-solid fa-file-lines"></i></h3>
            </div>
            <div class="redCardBack">
              <h3 className='flipCardIcon'>RESUME</h3>
            </div>
          </div>
        </div>
        </Link>
        </Col>

        <Col lg={3}>
        <Link to="/personal">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="greenCardFront">
            <h3 className='flipCardIcon'><i class="fa-solid fa-baseball"></i></h3>
            </div>
            <div class="greenCardBack">
            <h3 className='flipCardIcon'>PERSONAL LIFE</h3>
            </div>
          </div>
        </div>
        </Link>
        </Col>

        <Col lg={3}>
        <Link to="/contact">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="blueCardFront">
            <h3 className='flipCardIcon'><i class="fa-solid fa-envelope"></i></h3>
            </div>
            <div class="blueCardBack">
              <h3 className='flipCardIcon'>CONTACT ME</h3>
            </div>
          </div>
        </div>
        </Link>
        </Col>
      </Row>
    </div>
  </Container> 


</div>
);
}

export default About;